// pc
#company_visual {
    min-height: 500px;
    background-image: url(../../../public/img/img_company_visual.jpg?2022101301);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    h1 {
        @include fontsize(40);
        color: $baseColor;
    }
}

#facility_info {
    background-color: #F3FBFF;
    padding: 2rem 0 10rem 0;
    box-sizing: border-box;

    .container {
        width: 800px;
        max-width: 90%;
        height: auto;
        text-align: center;
        margin: 0 auto;
        background-color: #fff;
        border: 1px solid #bbb;
        padding: 5rem;
        box-sizing: border-box;

        h2 {
            margin-bottom: 5rem;
            @include fontsize(30);
            position: relative;
            line-height: 1.6;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .table {
            width: 100%;
            margin: 0 auto;

            .t_row {
                display: flex;
                text-align: left;
                border-bottom: 1px dotted $fontColor2;
                padding: 2rem 1rem;
                box-sizing: border-box;

                &:first-child {
                    border-top: 1px dotted $fontColor2;
                }

                .c_head {
                    width: 40%;
                    @include fontsize(20);
                }

                .c_data {
                    width: 60%;
                    @include fontsize(16);
                    line-height: 1.6;
                    letter-spacing: 0.1em;
                    color: $fontColor1;

                    a {
                        color: $fontColor1;
                    }

                    p {
                        &.classroom__label {
                            font-weight: 600;
                        }
                    }

                    .classroom {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
}
// pc end

// sp start
@media (max-width: 640px) {
    #company_visual {
        min-height: 300px;

        h1 {
            @include fontsize(30);
        }
    }

    #facility_info {
        .container {
            padding: 4rem 2rem;

            h2 {
                @include fontsize(24);
            }

            .table {
                .t_row {
                    @media (max-width: 500px) {
                        flex-direction: column;
                    }

                    .c_head {
                        @include fontsize(18);

                        @media (max-width: 500px) {
                            width: 100%;
                            margin-bottom: 1rem;
                        }
                    }

                    .c_data {
                        @include fontsize(15);

                        @media (max-width: 500px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// sp end