/* Font
------------------------------------------------------------*/

// 源柔ゴシックstart
@font-face {
  font-family: 'GenJyuuGothic-Medium';
  src: url('../fonts/GenJyuuGothic.woff') format('woff'),
    url('../fonts/GenJyuuGothic.woff2') format('woff2'),
    url('../fonts/GenJyuuGothic.ttf') format('truetype');
}

/* 標準のフォント */
.genJyuu-medium {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 400;
}

/* 細字のフォント */
.genJyuu-light {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 100;
}

/* 太字のフォント */
.genJyuu-bold {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* イタリック体のフォント */
.genJyuu-italic {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: italic;
  font-weight: 400;
}

// 源柔ゴシックend

// FOT-筑紫A丸ゴシック Std start
.tsuku-medium {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.tsuku-light {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.tsuku-bold {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.tsuku-italic {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: italic;
  font-weight: 400;
}

// FOT-筑紫A丸ゴシック Std end