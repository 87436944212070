// footer start
footer {
    background-color: $subColor;

    .container {
        width: 90%;
        max-width: 1500px;
        margin: 0 auto;
        padding-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: top;

        .footer_left {
            text-align: left;
            width: 80%;

            .footer_logo {
                width: 240px;
                margin-bottom: 1rem;
            }

            .wrap {
                display: flex;

                .classroom {
                    margin-right: 2rem;
                    margin-top: 2rem;
    
                    .classroom-txt {
                        @include fontsize(20);
                        font-weight: 600;
                        padding-bottom: 0.5rem;
                        display: flex;
                        align-items: center;
    
                        a {
                            display: block;
    
                            &.insta-link {
                                margin-left: 0.5rem;
                            }
    
                            &.line-link {
                                margin-left: 1rem;
                            }
    
                            img {
                                width: 25px;
                            }
                        }
                    }
    
                    .address {
                        @include fontsize(15);
                        margin-bottom: 0.5rem;
                        line-height: 1.2;
                    }
    
                    .tel-reception {
                        @include fontsize(14);
                        margin-bottom: 1rem;
                    }
    
                    .tel {
                        @include fontsize(16);
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.5rem;
    
                        img {
                            width: 25px;
                            margin-right: 0.5rem;
                        }
                    }
    
                    .mail-address {
                        @include fontsize(16);
                        display: flex;
                        align-items: center;
    
                        img {
                            width: 25px;
                            margin-right: 0.5rem;
    
                            &.atmark {
                                width: 15px;
                                margin-right: 0rem;
                            }
                        }
                    }
                }
            }
        }

        .footer_right {
            text-align: left;
            width: 20%;

            ul.footer_menu {
                li {
                    margin-bottom: 1.5rem;

                    a {
                        color: $fontColor1;
                        @include fontsize(16);
                        transition: color 0.5s;

                        &:hover {
                            color: #0182e6;
                        }
                    }

                    .child-menu {
                        margin-left: 1rem;
                        margin-top: 1rem;

                        li {
                            margin-bottom: 1rem;

                            a {
                                @include fontsize(15);
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright {
        text-align: center;
        @include fontsize(12);
        padding: 7rem 0 5rem 0;
    }


    @media (max-width: 960px) {
        .container {
            .footer_left {
                width: 75%;
            }

            .footer_right {
                padding-right: 0;
                width: 25%;
            }
        }
    }

    &#recruit2-foot {
        text-align: center;

        figure {
            width: 200px;
            max-width: 90%;
            margin: 0 auto;
            padding: 5rem 0;
        }

        .copyright {
            background-color: #00571B;
            padding: 2rem 0;
            color: #fff;
        }
    }
}

// footer end

@media (max-width: 900px) {

    // footer sp start
    footer {
        .container {
            padding-top: 3rem;
            display: block;

            .footer_left {
                display: block;
                width: 100%;

                .wrap {
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                }
            }

            .footer_right {
                padding-top: 3rem;
                width: 100%;
            }
        }
    }
}

body {
    &#page-guide {
        footer {
            background-color: $baseColor;
        }
    }
}