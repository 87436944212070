#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #d6f4ff;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        svg#logo {
            width: 200px;
            margin-bottom: 1.5rem;

            @media (max-width: 500px) {
                width: 150px;
                margin-bottom: 1rem;
            }
    
            .cls-1 {
                fill: #f49c00;
            }
    
            .cls-2 {
                fill: #f19ec2;
            }
    
            .cls-3 {
                fill: #ea5520;
            }
    
            .cls-4 {
                fill: #ffe100;
            }
    
            .cls-5 {
                fill: #8d93c8;
            }
    
            .cls-6 {
                fill: #7fcdec;
            }
    
            .cls-7 {
                fill: #54b983;
            }
        }
    
        svg#logo2 {
            width: 250px;
    
            @media (max-width: 500px) {
                width: 180px;
            }

            .cls-1 {
                fill: #4c4948;
            }
        }
    }

}