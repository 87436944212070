#recruit_visual {
    min-height: 500px;
    background-image: url(../../../public/img/img_recruit_visual.jpg?2022101301);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    h1 {
        @include fontsize(40);
        color: $baseColor;
    }
}

#recruit_info {
    background-color: $subColor;

    .container {
        width: 800px;
        max-width: 90%;
        margin: 0 auto;
        padding: 5rem 0 10rem 0;
        box-sizing: border-box;

        .title {
            text-align: center;
            margin-bottom: 5rem;

            h2 {
                @include fontsize(32);
                line-height: 1.4;
                position: relative;
                margin-bottom: 3rem;

                &::before {
                    content: "";
                    position: absolute;
                    bottom:-1rem;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: url("../../../public/img/img_parts_title_diary_03.png") center center no-repeat;
                    background-size: 100%;
                    width: 40px;
                    height: 15px;
                }
            }

            .explanation {
                line-height: 1.6;
            }
        }

        .contents {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            .recruit {
                width: 48%;
                margin-bottom: 5rem;
                cursor: pointer;
                transition: opacity 0.5s;

                &:hover {
                    opacity: 0.8;
                }

                figure {
                    position: relative;

                    img {
                        border: 1px solid #ccc;
                        border-radius: 1rem;
                    }

                    span {
                        position: absolute;
                        bottom: 0;
                        right: -1px;
                        display: inline-block;
                        background-color: #00187E;
                        color: #fff;
                        padding: 0.5rem 1rem;
                        box-sizing: border-box;
                        border-bottom-right-radius: 1rem;
                    }
                }

                .recruit-name {
                    text-align: center;
                    margin-top: 2rem;
                    font-weight: 600;
                    @include fontsize(16);
                    line-height: 1.4;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    #recruit_visual {
        min-height: 300px;

        h1 {
            @include fontsize(30);
        }
    }

    #recruit_info {
        .container {
            .title {
                h2 {
                    @include fontsize(26);
                }

                .explanation {
                    p {
                        @include fontsize(13);
                        margin-bottom: 1rem;
                    }
                }
            }

            .contents {
                .recruit {
                    @media (max-width: 500px) {
                        width: 100%;
                    }

                    .recruit-name {
                        @media (max-width: 500px) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}