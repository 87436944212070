#modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.9);
        cursor: pointer;
    }

    .wrapper {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 800px;
        max-width: 90%;
        background-color: #fff;
        padding: 5rem;
        box-sizing: border-box;

        h2 {
            text-align: center;
            @include fontsize(24);
            font-weight: 600;
            margin-bottom: 5rem;
            position: relative;
            line-height: 1;

            &::after {
                content: "";
                position: absolute;
                bottom:-1.5rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .tbl {
            width: 100%;

            .t-row {
                display: flex;
                align-items: top;
                padding: 2rem 0.5rem 2rem 1rem;
                border-bottom: 1px dotted #888;

                &:first-child {
                    border-top: 1px dotted #888;
                }

                .t-head {
                    width: 30%;
                    font-weight: 600;
                    font-family: fot-tsukuardgothic-std, sans-serif;
                }

                .t-data {
                    width: 70%;
                    font-family: fot-tsukuardgothic-std, sans-serif;

                    p {
                        margin-bottom: 1rem;

                        @media (max-width: 450px) {
                            margin-bottom: 0.2rem;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &.recruitment-type {
                        a {
                            display: block;
                            @include fontsize(12);
                            color: rgb(24, 82, 255);
                        }
                    }
                }
            }
        }
    }

    .btn-close {
        position: fixed;
        top: 2%;
        right: 2%;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 3px;
            background: #fff;
            transform: translate(-50%, -50%) rotate(45deg);
            box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 3px;
            background: #fff;
            transform: translate(-50%, -50%) rotate(-45deg);
            box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        }
    }

    @media (max-width: 768px) {
        .wrapper {
            @media (max-width: 500px) {
                padding: 3rem;
            }

            .tbl {
                .t-row {
                    @media (max-width: 500px) {
                        padding: 1rem 0.5rem;
                    }
                    @media (max-width: 450px) {
                        flex-direction: column;
                    }

                    .t-head {
                        width: 100%;

                        @media (max-width: 450px) {
                            margin-bottom: 0.5rem;
                        }
                    }
                    .t-data {
                        width: 100%;

                        @media (max-width: 500px) {
                            @include fontsize(14);
                        }
                    }
                }
            }
        }
    }
}