@charset "utf-8";

// pc
// about_visual pc start
#about_visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_about_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h1 {
    @include fontsize(40);
    color: $baseColor;
    text-align: center;
    max-width: 90%;
  }
}

// about_visual pc end

#about_support {
  .container {
    width: 1000px;
    max-width: 90%;
    height: auto;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 8rem;


    h2 {
      @include fontsize(25);
      padding-top: 3rem;
      line-height: 1.4;
      letter-spacing: 0.1em;
    }

    h3 {
      @include fontsize(20);
      padding-top: 2rem;
      line-height: 1.4;
      letter-spacing: 0.1em;
    }

    p {
      padding-top: 1rem;
      @include fontsize(16);
      line-height: 1.4;
      letter-spacing: 0.1em;
    }

    .about_support2 {
      h2 {
        padding-top: 5rem;
      }
    }
  }
}

// sp start
@media (max-width: 768px) {
  #about_visual {
    background-image: url(../../../public/img/img_about_visual_sp.jpg?2022101301);
    min-height: 300px;

    h1 {
        @include fontsize(30);
    }
  }
}

// sp end