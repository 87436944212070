@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 追加 */
a {
  text-decoration: none;
}

/* fontsize
------------------------------------------------------------*/
/* background
------------------------------------------------------------*/
/* Font
------------------------------------------------------------*/
@font-face {
  font-family: 'GenJyuuGothic-Medium';
  src: url("../fonts/GenJyuuGothic.woff") format("woff"), url("../fonts/GenJyuuGothic.woff2") format("woff2"), url("../fonts/GenJyuuGothic.ttf") format("truetype");
}

/* 標準のフォント */
.genJyuu-medium {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 400;
}

/* 細字のフォント */
.genJyuu-light {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 100;
}

/* 太字のフォント */
.genJyuu-bold {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* イタリック体のフォント */
.genJyuu-italic {
  font-family: 'GenJyuuGothic-Medium', sans-serif;
  font-style: italic;
  font-weight: 400;
}

.tsuku-medium {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.tsuku-light {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.tsuku-bold {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.tsuku-italic {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-style: italic;
  font-weight: 400;
}

/* base
------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

html body {
  font-family: 'GenJyuuGothic-Medium';
  color: #3e3e3e;
  min-width: 320px;
  max-width: 2500px;
  margin: 0 auto;
  font-size: 15px;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  html body {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.uppercase {
  text-transform: uppercase;
}

span.text {
  display: inline-block;
}

a {
  color: #333;
}

.card-hide {
  display: none;
}

#panlist {
  padding: 3rem 0;
  background-color: #F1FBFF;
}

#panlist.bg-white {
  background-color: #ffffff;
}

#panlist .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
}

#panlist .container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#panlist .container ul li {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6;
}

@media (max-width: 640px) {
  #panlist .container ul li {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#panlist .container ul li.home a {
  text-decoration: underline;
  color: #3e3e3e;
}

#panlist .container ul li a {
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

#panlist .container ul li a:hover, #panlist .container ul li a:active {
  color: #0182e6;
}

/* パソコンで見たときは"pc"のclassがついた画像が表示される */
.pc {
  display: inline !important;
}

.sp {
  display: none !important;
}

/* スマートフォンで見たときは"sp"のclassがついた画像が表示される */
@media only screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: inline !important;
  }
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #d6f4ff;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#loading .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#loading .wrap svg#logo {
  width: 200px;
  margin-bottom: 1.5rem;
}

@media (max-width: 500px) {
  #loading .wrap svg#logo {
    width: 150px;
    margin-bottom: 1rem;
  }
}

#loading .wrap svg#logo .cls-1 {
  fill: #f49c00;
}

#loading .wrap svg#logo .cls-2 {
  fill: #f19ec2;
}

#loading .wrap svg#logo .cls-3 {
  fill: #ea5520;
}

#loading .wrap svg#logo .cls-4 {
  fill: #ffe100;
}

#loading .wrap svg#logo .cls-5 {
  fill: #8d93c8;
}

#loading .wrap svg#logo .cls-6 {
  fill: #7fcdec;
}

#loading .wrap svg#logo .cls-7 {
  fill: #54b983;
}

#loading .wrap svg#logo2 {
  width: 250px;
}

@media (max-width: 500px) {
  #loading .wrap svg#logo2 {
    width: 180px;
  }
}

#loading .wrap svg#logo2 .cls-1 {
  fill: #4c4948;
}

header {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  min-width: 320px;
  max-width: 2500px;
  margin: 0 auto;
  height: auto;
  top: 0;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}

header .container {
  width: 90%;
  max-width: 1500px;
  margin: 1.5rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header .container .headA h1,
header .container .headA .head-logo {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

header .container .headA h1 a img,
header .container .headA .head-logo a img {
  width: 180px;
}

header .container .headB .nav_pc {
  display: block;
}

header .container .headB .nav_pc ul.nav-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

header .container .headB .nav_pc ul.nav-lists li {
  position: relative;
  display: inline-block;
}

header .container .headB .nav_pc ul.nav-lists li:not(:first-child) {
  margin-left: 2rem;
}

header .container .headB .nav_pc ul.nav-lists li a {
  text-align: center;
  display: block;
}

header .container .headB .nav_pc ul.nav-lists li a img {
  height: 45px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

header .container .headB .nav_pc ul.nav-lists li a div {
  margin-top: 0.5rem;
  color: #3e3e3e;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

@media (max-width: 1100px) {
  header .container .headB .nav_pc ul.nav-lists li a div {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

header .container .headB .nav_pc ul.nav-lists li a div i {
  margin-left: 0.3rem;
}

header .container .headB .nav_pc ul.nav-lists li .child-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -9rem;
  left: 0;
  width: 150px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}

header .container .headB .nav_pc ul.nav-lists li .child-menu li {
  margin-left: 0;
  height: 4rem;
  line-height: 4rem;
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
}

header .container .headB .nav_pc ul.nav-lists li .child-menu li:not(:last-child) {
  border-bottom: 1px dotted #333;
}

header .container .headB .nav_pc ul.nav-lists li .child-menu li a {
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

header .container .headB .nav_pc ul.nav-lists li .child-menu li:hover a, header .container .headB .nav_pc ul.nav-lists li .child-menu li:active a {
  color: #0182e6;
}

header .container .headB .nav_pc ul.nav-lists li:hover a img, header .container .headB .nav_pc ul.nav-lists li:active a img {
  opacity: 0.6;
}

header .container .headB .nav_pc ul.nav-lists li:hover a div, header .container .headB .nav_pc ul.nav-lists li:active a div {
  opacity: 0.6;
}

header .container .headB .nav_pc ul.nav-lists li:hover .child-menu, header .container .headB .nav_pc ul.nav-lists li:active .child-menu {
  opacity: 1;
  visibility: visible;
}

header .container .headB .nav_sp {
  display: none;
}

header .container .headC {
  display: none;
}

@media (max-width: 900px) {
  header {
    position: fixed;
  }
  header .container {
    position: relative;
    margin: 0.5rem auto;
  }
  header .container .headA {
    width: 100%;
  }
  header .container .headA h1,
  header .container .headA .head-logo {
    padding: 0;
  }
  header .container .headA h1 a img,
  header .container .headA .head-logo a img {
    width: 150px;
    margin: 1rem 0;
  }
  header .container .headB .nav_pc {
    display: none;
  }
  header .container .headB .nav_sp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.95);
    display: block;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.5s, visibility 0.5s;
    transition: opacity 0.5s, visibility 0.5s;
  }
  header .container .headB .nav_sp.is-active {
    opacity: 1;
    visibility: visible;
  }
  header .container .headB .nav_sp ul.nav-lists {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    margin-top: 10rem;
  }
  header .container .headB .nav_sp ul.nav-lists li {
    text-align: center;
    margin-bottom: 3rem;
  }
  header .container .headB .nav_sp ul.nav-lists li a {
    display: block;
    color: #3e3e3e;
    font-size: 24px;
    font-size: 2.4rem;
    cursor: pointer;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  header .container .headB .nav_sp ul.nav-lists li a i {
    margin-left: 0.5rem;
  }
  header .container .headB .nav_sp ul.nav-lists li a:hover {
    color: #0182e6;
  }
  header .container .headB .nav_sp ul.nav-lists li ul.child-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
    height: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    background-color: #00187E;
  }
  header .container .headB .nav_sp ul.nav-lists li ul.child-menu.is-active {
    visibility: visible;
    opacity: 1;
    margin-top: 2rem;
    height: auto;
  }
  header .container .headB .nav_sp ul.nav-lists li ul.child-menu li {
    margin-bottom: 1rem;
    padding: 1rem 0;
  }
  header .container .headB .nav_sp ul.nav-lists li ul.child-menu li:not(:last-child) {
    border-bottom: 1px dotted #fff;
    margin-bottom: 0;
  }
  header .container .headB .nav_sp ul.nav-lists li ul.child-menu li a {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    font-size: 1.8rem;
  }
  header .container .headC {
    display: block;
    position: absolute;
    top: 20%;
    right: 0;
  }
  header .container .headC .menu {
    width: 50px;
    height: 50px;
    position: relative;
  }
  header .container .headC .menu:before, header .container .headC .menu:after,
  header .container .headC .menu span {
    content: "";
    border-bottom: 2px solid #3e3e3e;
    position: absolute;
    top: 50%;
    left: 5px;
    right: 5px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 11;
  }
  header .container .headC .menu:before {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
  }
  header .container .headC .menu:after {
    -webkit-transform: translateY(13px);
            transform: translateY(13px);
  }
  header .container .headC .menu.is-active span {
    opacity: 0;
  }
  header .container .headC .menu.is-active::before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  header .container .headC .menu.is-active::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

header#early-rehabilitation {
  padding: 1rem 0;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

header#early-rehabilitation .container {
  width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header#early-rehabilitation .container .headA .head-logo img {
  width: 180px;
}

header#early-rehabilitation .container .headB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-size: 1.6rem;
}

header#early-rehabilitation .container .headB .tel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header#early-rehabilitation .container .headB .tel i {
  font-size: 30px;
  font-size: 3rem;
  margin-right: 1rem;
  color: #333;
}

header#early-rehabilitation .container .headB .tel .inner .number {
  display: block;
  font-size: 28px;
  font-size: 2.8rem;
  color: #333;
}

header#early-rehabilitation .container .headB .tel .inner span {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  color: #888;
  margin-top: 0.5rem;
  font-family: fot-tsukuardgothic-std, sans-serif;
}

header#early-rehabilitation .container .headB .line {
  font-size: 40px;
  font-size: 4rem;
  margin-left: 4rem;
  color: #333;
}

header#early-rehabilitation .container .headB .mail {
  font-size: 40px;
  font-size: 4rem;
  margin-left: 2rem;
  color: #333;
}

@media (max-width: 900px) {
  header#early-rehabilitation .container .headA {
    width: auto;
  }
  header#early-rehabilitation .container .headA .head-logo {
    margin-right: 2rem;
  }
  header#early-rehabilitation .container .headB {
    width: auto;
  }
  header#early-rehabilitation .container .headB .tel i {
    margin-right: 0;
  }
  header#early-rehabilitation .container .headB .tel .inner {
    display: none;
  }
  header#early-rehabilitation .container .headB .line {
    margin-left: 2rem;
  }
}

header#recruit2 .container-r2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header#recruit2 .container-r2 .headA {
  padding-left: 5%;
}

header#recruit2 .container-r2 .headA h1 a img,
header#recruit2 .container-r2 .headA .head-logo a img {
  width: 180px;
}

@media (max-width: 420px) {
  header#recruit2 .container-r2 .headA h1 a img,
  header#recruit2 .container-r2 .headA .head-logo a img {
    width: 150px;
  }
}

header#recruit2 .container-r2 .headB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header#recruit2 .container-r2 .headB .contact-link {
  display: inline-block;
  color: #00B939;
  text-align: center;
  padding: 1rem 1rem 1rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

header#recruit2 .container-r2 .headB .contact-link:hover, header#recruit2 .container-r2 .headB .contact-link:active {
  opacity: 0.6;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link {
    padding: 2rem 1rem 2rem 0;
  }
}

@media (max-width: 500px) {
  header#recruit2 .container-r2 .headB .contact-link {
    padding: 1rem 1rem 1rem 0;
  }
}

header#recruit2 .container-r2 .headB .contact-link span {
  background-color: #19B939;
  color: #fff;
  display: inline-block;
  padding: 0.2rem 0;
  width: 90%;
  border-radius: 3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link span {
    display: none;
  }
}

header#recruit2 .container-r2 .headB .contact-link .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

header#recruit2 .container-r2 .headB .contact-link .wrap img {
  width: 30px;
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link .wrap img {
    width: 40px;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

header#recruit2 .container-r2 .headB .contact-link .wrap .inner .tel-no {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link .wrap .inner .tel-no {
    display: none;
  }
}

header#recruit2 .container-r2 .headB .contact-link .wrap .inner .supplement {
  font-size: 12px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link .wrap .inner .supplement {
    display: none;
  }
}

header#recruit2 .container-r2 .headB .contact-link .wrap .inner .sp-label {
  display: none;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .contact-link .wrap .inner .sp-label {
    display: block;
  }
}

header#recruit2 .container-r2 .headB .mail-link {
  background-color: #00B939;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

header#recruit2 .container-r2 .headB .mail-link:hover, header#recruit2 .container-r2 .headB .mail-link:active {
  opacity: 0.6;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .mail-link {
    padding: 0 1.5rem;
  }
}

header#recruit2 .container-r2 .headB .mail-link img {
  width: 30px;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .mail-link img {
    width: 40px;
  }
}

header#recruit2 .container-r2 .headB .mail-link p.pc-label {
  display: block;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .mail-link p.pc-label {
    display: none;
  }
}

header#recruit2 .container-r2 .headB .mail-link p.sp-label {
  display: none;
}

@media (max-width: 768px) {
  header#recruit2 .container-r2 .headB .mail-link p.sp-label {
    display: block;
  }
}

.table-contents-menu {
  position: fixed;
  bottom: 5%;
  right: 5%;
  text-align: center;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: #FF9300;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  z-index: 5;
  cursor: pointer;
}

.table-contents-menu-body {
  display: none;
  position: fixed;
  bottom: 3%;
  right: 3%;
  width: 300px;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 4;
  padding: 3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.table-contents-menu-body h3 {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.table-contents-menu-body ul li {
  margin-bottom: 3rem;
}

.table-contents-menu-body ul li a {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
  position: relative;
}

.table-contents-menu-body ul li a::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 1px;
  border-bottom: 3px dotted #FF9300;
  bottom: -0.5rem;
  left: 0;
}

footer {
  background-color: #F1FBFF;
}

footer .container {
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
}

footer .container .footer_left {
  text-align: left;
  width: 80%;
}

footer .container .footer_left .footer_logo {
  width: 240px;
  margin-bottom: 1rem;
}

footer .container .footer_left .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .container .footer_left .wrap .classroom {
  margin-right: 2rem;
  margin-top: 2rem;
}

footer .container .footer_left .wrap .classroom .classroom-txt {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .container .footer_left .wrap .classroom .classroom-txt a {
  display: block;
}

footer .container .footer_left .wrap .classroom .classroom-txt a.insta-link {
  margin-left: 0.5rem;
}

footer .container .footer_left .wrap .classroom .classroom-txt a.line-link {
  margin-left: 1rem;
}

footer .container .footer_left .wrap .classroom .classroom-txt a img {
  width: 25px;
}

footer .container .footer_left .wrap .classroom .address {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

footer .container .footer_left .wrap .classroom .tel-reception {
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

footer .container .footer_left .wrap .classroom .tel {
  font-size: 16px;
  font-size: 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.5rem;
}

footer .container .footer_left .wrap .classroom .tel img {
  width: 25px;
  margin-right: 0.5rem;
}

footer .container .footer_left .wrap .classroom .mail-address {
  font-size: 16px;
  font-size: 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .container .footer_left .wrap .classroom .mail-address img {
  width: 25px;
  margin-right: 0.5rem;
}

footer .container .footer_left .wrap .classroom .mail-address img.atmark {
  width: 15px;
  margin-right: 0rem;
}

footer .container .footer_right {
  text-align: left;
  width: 20%;
}

footer .container .footer_right ul.footer_menu li {
  margin-bottom: 1.5rem;
}

footer .container .footer_right ul.footer_menu li a {
  color: #3e3e3e;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

footer .container .footer_right ul.footer_menu li a:hover {
  color: #0182e6;
}

footer .container .footer_right ul.footer_menu li .child-menu {
  margin-left: 1rem;
  margin-top: 1rem;
}

footer .container .footer_right ul.footer_menu li .child-menu li {
  margin-bottom: 1rem;
}

footer .container .footer_right ul.footer_menu li .child-menu li a {
  font-size: 15px;
  font-size: 1.5rem;
}

footer .copyright {
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  padding: 7rem 0 5rem 0;
}

@media (max-width: 960px) {
  footer .container .footer_left {
    width: 75%;
  }
  footer .container .footer_right {
    padding-right: 0;
    width: 25%;
  }
}

footer#recruit2-foot {
  text-align: center;
}

footer#recruit2-foot figure {
  width: 200px;
  max-width: 90%;
  margin: 0 auto;
  padding: 5rem 0;
}

footer#recruit2-foot .copyright {
  background-color: #00571B;
  padding: 2rem 0;
  color: #fff;
}

@media (max-width: 900px) {
  footer .container {
    padding-top: 3rem;
    display: block;
  }
  footer .container .footer_left {
    display: block;
    width: 100%;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  footer .container .footer_left .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 900px) {
  footer .container .footer_right {
    padding-top: 3rem;
    width: 100%;
  }
}

body#page-guide footer {
  background-color: #ffffff;
}

#visual {
  width: 100%;
  height: 100vh;
}

#visual .container {
  width: 100%;
  height: 100%;
}

#visual .container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#classroom-info {
  background-color: #F1FBFF;
  padding: 100px 0;
}

#classroom-info .container {
  width: 900px;
  max-width: 90%;
  margin: 0 auto;
}

#classroom-info .container .title {
  margin-bottom: 80px;
}

#classroom-info .container .title h2 {
  text-align: center;
  font-size: 40px;
  font-size: 4rem;
  position: relative;
  line-height: 1.6;
}

#classroom-info .container .title h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url("../../../public/img/img_parts_title_diary_03.png") center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#classroom-info .container .contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#classroom-info .container .contents .classroom {
  width: 48%;
}

#classroom-info .container .contents .classroom a.pic {
  display: inline-block;
  position: relative;
  -webkit-transition: opacity 0.7s;
  transition: opacity 0.7s;
}

#classroom-info .container .contents .classroom a.pic img {
  border-radius: 1rem;
  border: 1px solid #eee;
}

#classroom-info .container .contents .classroom a.pic span {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 24, 126, 0.9);
  color: #fff;
  padding: 0.8rem 1rem;
  border-bottom-right-radius: 1rem;
}

#classroom-info .container .contents .classroom a.pic span i {
  margin-left: 0.3rem;
}

#classroom-info .container .contents .classroom a.pic:hover {
  opacity: 0.5;
}

#classroom-info .container .contents .classroom .classroom__info .sub-title {
  margin: 1.5rem 0;
}

#classroom-info .container .contents .classroom .classroom__info .sub-title h3 {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 1rem;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_lbl {
  border: 1px solid #333;
  width: 60px;
  text-align: center;
  margin-right: 1rem;
  padding: 0.2rem 0;
  font-weight: 700;
  background-color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_txt {
  width: calc(100% - 60px);
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.4;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_txt.telno {
  padding: 0.2rem 0;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_txt a.g-map {
  color: #00C4FF;
  -webkit-transition: -webkit-text-decoration 0.5s;
  transition: -webkit-text-decoration 0.5s;
  transition: text-decoration 0.5s;
  transition: text-decoration 0.5s, -webkit-text-decoration 0.5s;
  margin-top: 0.5rem;
  display: inline-block;
  position: relative;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_txt a.g-map::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  height: 1.5px;
  background: #00C4FF;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

#classroom-info .container .contents .classroom .classroom__info .tbl .t_row .t_txt a.g-map:hover::before {
  opacity: 1;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.insta-link {
  margin-top: 0.5rem;
  padding-right: 2rem;
  position: relative;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.insta-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #555;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.insta-link img {
  width: 28px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.insta-link:hover img {
  opacity: 0.5;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.tiktok-link {
  margin-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.tiktok-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #555;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.tiktok-link img {
  width: 28px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.tiktok-link:hover img {
  opacity: 0.5;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.litalico-banner {
  display: block;
  padding-left: 2rem;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.litalico-banner img {
  width: 150px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info .container .contents .classroom .classroom__info .classroom_sns a.litalico-banner:hover img {
  opacity: 0.5;
}

#trouble {
  padding: 10rem 0 15rem 0;
}

#trouble .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#trouble .container .title {
  font-size: 35px;
  font-size: 3.5rem;
  margin-bottom: 10rem;
  line-height: 1.6;
}

#trouble .container .title .emphasis {
  font-size: 50px;
  font-size: 5rem;
  text-decoration: underline;
}

#trouble .container picture img {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}

#trouble .container .under {
  width: 800px;
  max-width: 90%;
  background-color: #fff;
  font-size: 40px;
  font-size: 4rem;
  margin: 10rem auto 0 auto;
  padding: 1.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.2;
  background: linear-gradient(120deg, #EA5520, #F49C00, #FFE100, #54B983, #7FCDEC, #8D93C8, #F19EC2);
  position: relative;
}

#trouble .container .under .wrap {
  background: #fff;
  padding: 3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#trouble .container .under .wrap img {
  width: 250px;
  margin-right: 2rem;
}

#trouble .container .under img.staff {
  position: absolute;
  top: 0;
  left: -25rem;
  width: 200px;
}

@media (max-width: 1200px) {
  #trouble .container .under img.staff {
    left: -15rem;
  }
}

@media (max-width: 1000px) {
  #trouble .container .under img.staff {
    display: none;
  }
}

@media (max-width: 1024px) {
  #trouble .container img {
    width: 80%;
  }
  #trouble .container p {
    font-size: 22px;
    font-size: 2.2rem;
    margin: 7rem 0;
  }
  #trouble .container p .emphasis {
    font-size: 38px;
    font-size: 3.8rem;
  }
}

#about {
  background-color: #F1FBFF;
  padding: 10rem 0;
}

#about .container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 10rem;
}

#about .container h2 {
  text-align: center;
  font-size: 35px;
  font-size: 3.5rem;
  position: relative;
  margin-bottom: 8rem;
  line-height: 1.6;
}

#about .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#about .container .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}

#about .container .inner .wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#about .container .inner .wrap figure {
  width: 48%;
}

#about .container .inner .wrap figure img {
  border-radius: 1rem;
  border: 1px solid #ddd;
}

#about .container .inner .wrap .catchphrase {
  width: 48%;
  text-align: left;
  font-weight: bold;
}

#about .container .inner .wrap .catchphrase p {
  letter-spacing: 0.3rem;
  font-size: 28px;
  font-size: 2.8rem;
}

#about .container .inner .wrap .catchphrase p:first-child {
  margin-bottom: 2rem;
}

#about .container .inner .wrap .catchphrase p:last-child {
  font-size: 32px;
  font-size: 3.2rem;
}

#about .container .inner .wrap .catchphrase p img {
  width: 100px;
  margin-right: 2rem;
  vertical-align: bottom;
}

#about .container .inner div.top {
  position: relative;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #ffffff;
  width: 70%;
  margin: -3rem auto 0 auto;
  padding: 6rem 2rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 5px #ddd;
          box-shadow: 0px 1px 5px #ddd;
}

#about .container .inner div.top #imgImportant {
  display: block;
  width: 500px;
  max-width: 100%;
  margin: 3rem auto 0 auto;
}

#about .container .inner div.top .rainbow {
  position: absolute;
  top: -5rem;
  right: -6rem;
  width: 150px;
}

#about .container .inner div.top .about_support {
  position: absolute;
  width: 20%;
  min-width: 120px;
  bottom: -5rem;
  left: -8rem;
}

#about .container .inner div.top .about_support:hover {
  opacity: 0.7;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

#character {
  padding: 10rem 0 15rem 0;
}

#character .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

#character .container h2 {
  font-size: 35px;
  font-size: 3.5rem;
  margin-bottom: 10rem;
  position: relative;
  line-height: 1.6;
}

#character .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#character .container figure a {
  display: block;
}

#character .container figure a img {
  padding-bottom: 3rem;
}

@media (max-width: 980px) {
  #character .container figure a img {
    width: 70%;
  }
}

#character .container .elements {
  position: relative;
  margin-top: 3rem;
}

#character .container .elements .airplane {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1040px) {
  #character .container .elements .airplane {
    width: 15%;
  }
}

@media (max-width: 500px) {
  #character .container .elements .airplane {
    width: 18%;
  }
}

#character .container .elements .flower {
  position: absolute;
  bottom: -30rem;
  right: 0;
}

@media (max-width: 1040px) {
  #character .container .elements .flower {
    bottom: -25rem;
    width: 20%;
  }
}

@media (max-width: 500px) {
  #character .container .elements .flower {
    bottom: -6rem;
    width: 30%;
  }
}

#character .container .elements a {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#character .container .elements a:hover, #character .container .elements a:active {
  opacity: 0.8;
}

#character .container p {
  font-size: 20px;
  font-size: 2rem;
  margin: 8rem 0 2rem 0;
  letter-spacing: 0.1em;
  line-height: 1.6;
}

@media (max-width: 980px) {
  #character .container p {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

#character .container a.morebtn {
  color: #ffffff;
  font-size: 27px;
  font-size: 2.7rem;
  background-color: #00187E;
  padding: 3rem 0;
  width: 300px;
  max-width: 100%;
  border-radius: 5rem;
  display: block;
  margin: 0 auto;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

#character .container a.morebtn span {
  padding-left: 3rem;
}

#character .container a.morebtn:hover {
  background-color: #0182e6;
}

#guide {
  background-color: #F1FBFF;
  padding: 10rem 0 15rem 0;
}

#guide .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#guide .container h2 {
  font-size: 35px;
  font-size: 3.5rem;
  margin-bottom: 8rem;
  position: relative;
  line-height: 1.6;
}

#guide .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#guide .container .elements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#guide .container .elements .element {
  width: 30%;
  margin-bottom: 8rem;
}

#guide .container .elements .element .step {
  font-size: 30px;
  font-size: 3rem;
  font-weight: 600;
}

#guide .container .elements .element figure {
  margin: 1rem 0;
}

#guide .container .elements .element .stepTitle {
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: 600;
}

#guide .container .elements .element p {
  font-size: 18px;
  font-size: 1.8rem;
  margin-top: 3rem;
  line-height: 1.2;
}

#guide .container .elements .element p a {
  color: #3e3e3e;
  text-decoration: underline;
}

#guide .container .elements .element:nth-child(3) p {
  margin-top: 3rem;
}

#guide .container .next_guide p {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 5rem;
}

#guide .container .next_guide a {
  color: #ffffff;
  font-size: 27px;
  font-size: 2.7rem;
  background-color: #00187E;
  padding: 2rem 3rem;
  border-radius: 5rem;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

#guide .container .next_guide a span {
  padding-left: 3rem;
}

#guide .container .next_guide a:hover {
  background-color: #0182e6;
}

#contact {
  background-image: url(../../../public/img/img_contact_bg_dot.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10rem 0 15rem 0;
}

#contact .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#contact .container h2 {
  font-size: 35px;
  font-size: 3.5rem;
  position: relative;
  line-height: 1.6;
}

#contact .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#contact .container .tel_mail_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 12rem;
  line-height: 1;
}

#contact .container .tel_mail_box .telephone {
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 30px;
  width: 48%;
  height: 30rem;
  position: relative;
}

#contact .container .tel_mail_box .telephone div.icon_area {
  position: absolute;
  top: -5rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  background-color: #edf6f6;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 50%;
}

#contact .container .tel_mail_box .telephone .inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#contact .container .tel_mail_box .telephone .inner .sub-title {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

#contact .container .tel_mail_box .telephone .inner .tel-no span {
  display: block;
  margin-bottom: 0.5rem;
}

#contact .container .tel_mail_box .telephone .inner .tel-no a {
  font-size: 30px;
  font-size: 3rem;
  text-decoration: none;
  color: #3e3e3e;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  display: block;
}

#contact .container .tel_mail_box .telephone .inner .tel-no a:hover, #contact .container .tel_mail_box .telephone .inner .tel-no a:active {
  color: #0182e6;
}

#contact .container .tel_mail_box .telephone .inner .supplement {
  margin-top: 1.5rem;
  font-size: 12px;
  font-size: 1.2rem;
}

#contact .container .tel_mail_box .mail {
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 30px;
  width: 48%;
  height: 30rem;
  position: relative;
}

#contact .container .tel_mail_box .mail div.icon_area {
  position: absolute;
  top: -5rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  background-color: #f8e8eb;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 50%;
}

#contact .container .tel_mail_box .mail .inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#contact .container .tel_mail_box .mail .inner p {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 4rem;
}

#contact .container .tel_mail_box .mail .inner a {
  color: #3e3e3e;
  background-color: #ffffff;
  border: 1px solid #3e3e3e;
  border-radius: 3rem;
  padding: 2rem 3rem;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

#contact .container .tel_mail_box .mail .inner a:hover {
  background-color: #d5edff;
}

@media (max-width: 900px) {
  #visual {
    background-position: 70%;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-info {
    padding: 70px 0 100px 0;
  }
}

@media (max-width: 900px) {
  #classroom-info .container .title {
    margin-bottom: 50px;
  }
  #classroom-info .container .title h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-info .container .contents {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-info .container .contents .classroom {
    width: 100%;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-info .container .contents .classroom:nth-child(1) {
    margin-bottom: 5rem;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-info .container .contents .classroom a.pic span {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #trouble {
    padding: 8rem 0;
  }
}

@media (max-width: 900px) {
  #trouble .container .title {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #trouble .container .title {
    margin-bottom: 5rem;
  }
}

@media (max-width: 900px) {
  #trouble .container .title .emphasis {
    font-size: 32px;
    font-size: 3.2rem;
    border-bottom: none;
    text-decoration: underline;
  }
  #trouble .container .under {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  #trouble .container .under {
    max-width: 100%;
    margin: 5rem auto 0 auto;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #trouble .container .under {
    padding: 0.6rem;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #trouble .container .under .wrap {
    padding: 1rem 0.5rem;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  #trouble .container .under .wrap img {
    width: 180px;
  }
}

@media (max-width: 900px) {
  #about .container {
    height: auto;
  }
  #about .container h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
  #about .container .inner {
    display: block;
  }
  #about .container .inner .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #about .container .inner .wrap figure {
    width: 100%;
  }
  #about .container .inner .wrap figure img {
    width: 100%;
    border-radius: 2rem;
  }
  #about .container .inner .wrap .catchphrase {
    width: 100%;
    text-align: center;
    margin: 3rem 0;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #about .container .inner .wrap .catchphrase p {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media (max-width: 900px) {
  #about .container .inner div.top {
    width: 100%;
    margin-top: 0;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media (max-width: 900px) and (max-width: 550px) {
  #about .container .inner div.top {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 900px) and (max-width: 400px) {
  #about .container .inner div.top {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media (max-width: 900px) {
  #about .container .inner div.top .rainbow {
    top: -3rem;
    right: 1rem;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  #about .container .inner div.top .rainbow {
    width: 100px;
    right: 0;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #about .container .inner div.top .rainbow {
    width: 30%;
  }
}

@media (max-width: 900px) {
  #about .container .inner div.top .about_support {
    bottom: -10rem;
    left: 0;
    width: 20%;
  }
  #character .container h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #character .container a.morebtn {
    font-size: 20px;
    font-size: 2rem;
  }
  #character .container a.morebtn span img {
    width: 10px;
  }
}

@media (max-width: 900px) {
  #guide .container h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
  #guide .container .elements {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  #guide .container .elements .element {
    width: 48%;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #guide .container .elements .element {
    margin-bottom: 5rem;
  }
}

@media (max-width: 900px) {
  #guide .container .elements .element .step {
    font-size: 25px;
    font-size: 2.5rem;
  }
  #guide .container .elements .element .stepTitle {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #guide .container .elements .element p {
    font-size: 14px;
    font-size: 1.4rem;
  }
  #guide .container .next_guide p {
    font-size: 16px;
    font-size: 1.6rem;
  }
  #guide .container .next_guide a {
    font-size: 20px;
    font-size: 2rem;
  }
  #guide .container .next_guide a span img {
    width: 10px;
  }
}

@media (max-width: 900px) {
  #contact {
    background-image: url(../../../public/img/img_contact_bg_dot_sp.jpg?2022101301);
    padding: 10rem 0 0 0;
  }
  #contact .container {
    padding-bottom: 5rem;
  }
  #contact .container h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
  #contact .container .tel_mail_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #contact .container .tel_mail_box .telephone,
  #contact .container .tel_mail_box .mail {
    width: 80%;
    margin-bottom: 10rem;
  }
}

@media (max-width: 900px) and (max-width: 550px) {
  #contact .container .tel_mail_box .telephone,
  #contact .container .tel_mail_box .mail {
    width: 100%;
  }
}

#classroom-visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_classroom_visual.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#classroom-visual h2 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

#classroom-info-dtl {
  padding: 5rem 0 10rem 0;
}

#classroom-info-dtl .container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#classroom-info-dtl .container .l-area {
  width: 49%;
}

#classroom-info-dtl .container .r-area {
  width: 49%;
}

#classroom-info-dtl .container .r-area .classroom__info {
  margin-top: 2rem;
}

#classroom-info-dtl .container .r-area .classroom__info h3 {
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 2rem;
}

#classroom-info-dtl .container .r-area .classroom__info .tbl .t_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 1rem;
}

#classroom-info-dtl .container .r-area .classroom__info .tbl .t_row .t_lbl {
  width: 50px;
  margin-right: 1rem;
  padding: 0.2rem 0;
  border: 1px solid #333;
  display: inline;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}

#classroom-info-dtl .container .r-area .classroom__info .tbl .t_row .t_txt {
  width: calc(100% - 50px);
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.4;
}

#classroom-info-dtl .container .r-area .classroom__info .tbl .t_row .t_txt.telno {
  padding: 0.2rem 0;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.insta-link {
  margin-top: 0.5rem;
  margin-right: 2rem;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.insta-link img {
  width: 28px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.insta-link:hover img {
  opacity: 0.5;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.tiktok-link {
  margin-top: 0.5rem;
  margin-right: 2rem;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.tiktok-link img {
  width: 28px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.tiktok-link:hover img {
  opacity: 0.5;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.litalico-banner {
  display: block;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.litalico-banner img {
  width: 200px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#classroom-info-dtl .container .r-area .classroom__info .classroom_sns a.litalico-banner:hover img {
  opacity: 0.5;
}

#classroom-info-dtl .container .r-area .classroom-map {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

#classroom-info-dtl .container .r-area .classroom-map iframe {
  width: 100%;
  height: 560px;
  margin-top: -170px;
}

#classroom-gallery {
  padding: 3rem 0 10rem 0;
}

#classroom-gallery .container {
  width: 1500px;
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#classroom-gallery .container figure {
  width: 32%;
  margin-bottom: 2.5rem;
}

#classroom-assessment-sheet {
  padding: 10rem 0 20rem 0;
}

#classroom-assessment-sheet .container {
  width: 1000px;
  max-width: 90%;
  margin: 0 auto;
}

#classroom-assessment-sheet .container .title {
  text-align: center;
  margin-bottom: 8rem;
}

#classroom-assessment-sheet .container .title h3 {
  font-size: 40px;
  font-size: 4rem;
  position: relative;
  line-height: 1.6;
}

#classroom-assessment-sheet .container .title h3::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#classroom-assessment-sheet .container .downloadContents .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px dashed #888;
  padding: 2rem;
}

#classroom-assessment-sheet .container .downloadContents .card:first-child {
  border-top: 1px dashed #888;
}

#classroom-assessment-sheet .container .downloadContents .card figure {
  width: 70px;
  margin-right: 1rem;
}

#classroom-assessment-sheet .container .downloadContents .card .inner {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#classroom-assessment-sheet .container .downloadContents .card .inner .fileName {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1.2;
}

#classroom-assessment-sheet .container .downloadContents .card .inner a {
  display: block;
  width: 200px;
  max-width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #00e297;
  border: 2px solid #00e297;
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 2rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#classroom-assessment-sheet .container .downloadContents .card .inner a:hover, #classroom-assessment-sheet .container .downloadContents .card .inner a:active {
  background-color: #fff;
  color: #00e297;
}

@media (max-width: 900px) and (max-width: 600px) {
  #classroom-visual {
    min-height: 400px;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  #classroom-visual h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media (max-width: 900px) {
  #classroom-info-dtl {
    padding: 3rem 0 8rem 0;
  }
  #classroom-info-dtl .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #classroom-info-dtl .container .l-area {
    width: 100%;
    text-align: center;
  }
  #classroom-info-dtl .container .l-area img {
    width: 500px;
  }
  #classroom-info-dtl .container .r-area {
    width: 100%;
  }
  #classroom-info-dtl .container .r-area .classroom__info h3 {
    font-size: 22px;
    font-size: 2.2rem;
    text-align: center;
    margin: 1rem 0;
  }
  #classroom-info-dtl .container .r-area .classroom__info .tbl {
    width: 60%;
    margin: 0 auto;
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  #classroom-info-dtl .container .r-area .classroom__info .tbl {
    width: 80%;
  }
}

@media (max-width: 900px) and (max-width: 450px) {
  #classroom-info-dtl .container .r-area .classroom__info .tbl {
    width: 100%;
  }
}

@media (max-width: 900px) {
  #classroom-info-dtl .container .r-area .classroom__info .tbl .t_row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #classroom-info-dtl .container .r-area .classroom__info .classroom_sns {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #classroom-gallery {
    padding-bottom: 6rem;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  #classroom-gallery .container figure {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 900px) and (max-width: 768px) and (max-width: 640px) {
  #classroom-assessment-sheet .container .title h3 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media (max-width: 900px) and (max-width: 768px) {
  #classroom-assessment-sheet .container .downloadContents .card {
    padding: 2rem 1rem;
  }
}

@media (max-width: 900px) and (max-width: 768px) and (max-width: 380px) {
  #classroom-assessment-sheet .container .downloadContents .card {
    padding: 2rem 0;
  }
}

@media (max-width: 900px) and (max-width: 768px) {
  #classroom-assessment-sheet .container .downloadContents .card .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

@media (max-width: 900px) and (max-width: 768px) and (max-width: 500px) {
  #classroom-assessment-sheet .container .downloadContents .card .inner .fileName {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

#guide-visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_guide_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#guide-visual h1 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

#flow {
  background-color: #F1FBFF;
  padding: 10rem 0;
}

@media (max-width: 500px) {
  #flow {
    padding: 5rem 0;
  }
}

#flow .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#flow .container h2 {
  font-size: 40px;
  font-size: 4rem;
  margin-bottom: 5rem;
}

@media (min-width: 700px) {
  #flow .container .wrap_pc {
    display: block;
  }
}

@media (max-width: 700px) {
  #flow .container .wrap_pc {
    display: none;
  }
}

#flow .container .wrap_pc .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5rem 0;
}

#flow .container .wrap_pc .step:nth-child(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

#flow .container .wrap_pc .step .img_star {
  width: 53%;
}

#flow .container .wrap_pc .step .img_star img {
  width: 50%;
}

#flow .container .wrap_pc .step .sentence {
  width: 47%;
  background-color: #ffffff;
  text-align: left;
  padding: 3rem;
  border-radius: 1rem;
}

#flow .container .wrap_pc .step .sentence h3 {
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  line-height: 1.2;
  font-weight: 600;
}

#flow .container .wrap_pc .step .sentence p {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 1.2;
  color: #3e3e3e;
}

#flow .container .wrap_pc .step .sentence p a {
  color: #3e3e3e;
}

@media (min-width: 700px) {
  #flow .container .wrap_sp {
    display: none;
  }
}

@media (max-width: 700px) {
  #flow .container .wrap_sp {
    display: block;
  }
}

#flow .container .wrap_sp figure {
  margin-bottom: 5rem;
}

#flow .container .wrap_sp .stepBody .sentence {
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  padding: 3rem 1.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 1rem;
  margin-bottom: 3rem;
  min-height: 200px;
}

#flow .container .wrap_sp .stepBody .sentence h3 {
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  line-height: 1.2;
  font-weight: 600;
}

#flow .container .wrap_sp .stepBody .sentence p {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: 1.2;
  color: #3e3e3e;
}

#flow .container .wrap_sp .stepBody .sentence p a {
  color: #3e3e3e;
}

#time {
  padding: 10rem 0 20rem 0;
}

#time .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 5rem 0;
}

#time .container h2 {
  font-size: 40px;
  font-size: 4rem;
}

#time .container .time_flex {
  text-align: left;
  margin-top: 15rem;
}

#time .container .time_flex .time-sp {
  display: none;
}

#price {
  background-color: #F1FBFF;
  padding: 15rem 0 5rem 0;
}

#price .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#price .container h2 {
  font-size: 40px;
  font-size: 4rem;
}

#price .container .lead {
  background-color: #C6EFFD;
  position: relative;
  width: 80%;
  margin: 5rem auto;
  padding: 8rem 0;
  border-radius: 3rem;
}

#price .container .lead p {
  font-size: 25px;
  font-size: 2.5rem;
  letter-spacing: 0.1em;
  line-height: 1.3;
}

#price .container .lead p strong {
  font-size: 50px;
  font-size: 5rem;
}

#price .container .lead .rainbow {
  position: absolute;
  top: -3rem;
  right: -3rem;
  width: 150px;
}

#price .container .lead2 {
  margin: 5rem 0 3rem 0;
}

#price .container .lead2 p {
  font-size: 25px;
  font-size: 2.5rem;
  letter-spacing: 0.1em;
  line-height: 1.2;
}

#price .container .lead2 p:nth-child(2) {
  font-size: 20px;
  font-size: 2rem;
  color: #5e5e5e;
}

#price .container .lead2 p span.max {
  font-size: 40px;
  font-size: 4rem;
}

#price .container .table .t_head,
#price .container .table .t_body {
  display: table;
  width: 100%;
}

#price .container .table .t_head .c_head,
#price .container .table .t_body .c_head {
  display: table-cell;
  width: 33.3333%;
  background-color: #F6D7DD;
  font-size: 20px;
  font-size: 2rem;
  padding: 2rem 3rem;
  border: 1px solid #3e3e3e;
  letter-spacing: 0.1em;
  line-height: 1.2;
}

#price .container .table .t_head .c_head span,
#price .container .table .t_body .c_head span {
  font-size: 14px;
  font-size: 1.4rem;
}

#price .container .table .t_head .c_data,
#price .container .table .t_body .c_data {
  display: table-cell;
  border: solid 1px #3e3e3e;
  width: 33.3333%;
  background-color: #f9f1f3;
  border: 1px solid #3e3e3e;
  padding: 3rem;
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0.1em;
}

#price .container .table .t_head .c_data span,
#price .container .table .t_body .c_data span {
  font-size: 30px;
  font-size: 3rem;
}

#price .container .guide_girl {
  text-align: left;
  padding: 3rem 0 8rem 0;
}

#price .container .guide_girl img {
  width: 50%;
}

@media (max-width: 900px) {
  #price .container .guide_girl img {
    width: 70%;
  }
}

footer.guide {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  #price .container .lead p {
    font-size: 20px;
    font-size: 2rem;
  }
  #price .container .lead2 p {
    font-size: 20px;
    font-size: 2rem;
  }
  #price .container .table {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  #price .container .table .t_head,
  #price .container .table .t_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #price .container .table .t_head .c_head,
  #price .container .table .t_body .c_head {
    width: 100%;
    height: 30%;
    padding: 2rem 0;
  }
  #price .container .table .t_head .c_data,
  #price .container .table .t_body .c_data {
    width: 100%;
    height: 30%;
    padding: 2rem 0;
  }
}

@media (max-width: 700px) {
  #price .container .lead2 p:nth-child(2) {
    margin-top: 2rem;
    font-size: 16px;
    font-size: 1.6rem;
  }
  #flow .container .wrap_pc .step {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #flow .container .wrap_pc .step:nth-child(odd) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #flow .container .wrap_pc .step .img_star {
    width: auto;
  }
  #flow .container .wrap_pc .step .sentence {
    width: 70%;
    margin: 0 auto;
    margin-top: 3rem;
    padding-bottom: 8rem;
  }
  #flow .container .wrap_pc .step .sentence h3 {
    text-align: center;
  }
  #flow .container .wrap_pc .step .sentence p {
    padding: 0 3rem;
  }
}

@media (max-width: 640px) {
  #guide-visual {
    min-height: 300px;
  }
  #guide-visual h1 {
    font-size: 30px;
    font-size: 3rem;
  }
  #flow .container h2 {
    font-size: 30px;
    font-size: 3rem;
  }
  #flow .container .wrap_pc .step .sentence {
    width: auto;
    margin: 3rem 0 0 0;
  }
  #flow .container .wrap_pc .step .sentence p {
    padding: 0;
  }
  #time {
    padding: 6rem 0;
  }
  #time .container h2 {
    font-size: 30px;
    font-size: 3rem;
  }
  #time .container .time_flex {
    margin-top: 5rem;
  }
}

@media (max-width: 640px) and (max-width: 500px) {
  #time .container .time_flex .time-sp {
    display: block;
    margin-top: 3rem;
  }
}

@media (max-width: 640px) {
  #price .container h2 {
    padding-top: 2rem;
    font-size: 30px;
    font-size: 3rem;
  }
  #price .container .lead {
    width: auto;
    padding: 5rem 0;
    border-radius: 2rem;
  }
  #price .container .lead .rainbow {
    width: 100px;
    right: -1rem;
    top: -1rem;
  }
}

@media (max-width: 640px) and (max-width: 450px) {
  #price .container .lead .rainbow {
    width: 80px;
  }
}

@media (max-width: 640px) {
  #price .container .lead2 p:nth-child(2) {
    margin-top: 2rem;
    font-size: 16px;
    font-size: 1.6rem;
  }
  #price .container .table .t_head .c_head {
    font-size: 16px;
    font-size: 1.6rem;
  }
  #price .container .table .t_head .c_head span {
    font-size: 11px;
    font-size: 1.1rem;
  }
  #price .container .guide_girl img {
    width: 100%;
  }
}

@media (max-width: 400px) {
  #price .container .guide_woman {
    width: 40%;
    margin: 0 auto;
  }
}

main #attemptVisual {
  height: 500px;
  background-image: url(../../../public/img/img_attempt_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

main #attemptVisual h1 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

main #panlist.p_attempt {
  background-color: #fff;
}

main #attempt {
  font-size: 16px;
  font-size: 1.6rem;
  padding-bottom: 10rem;
}

main #attempt .title {
  text-align: center;
  margin-top: 5rem;
}

main #attempt .title h2 {
  font-size: 35px;
  font-size: 3.5rem;
  display: inline-block;
  margin: 0 auto;
  position: relative;
  letter-spacing: 0.1em;
}

main #attempt .title h2 span.number {
  color: #F500FF;
  font-size: 60px;
  font-size: 6rem;
}

main #attempt .title h2 span.strSmall {
  font-size: 28px;
  font-size: 2.8rem;
}

main #attempt .title h2::before {
  position: absolute;
  content: "";
  top: 0;
  left: -8rem;
  width: 2px;
  height: 120%;
  background-color: #3e3e3e;
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
}

main #attempt .title h2::after {
  position: absolute;
  content: "";
  top: 0;
  right: -8rem;
  width: 2px;
  height: 120%;
  background-color: #3e3e3e;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
}

main #attempt .title p {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 5rem 0;
  letter-spacing: 0.1em;
  line-height: 1.4;
}

main #attempt .elements #learningSupport,
main #attempt .elements #individualSupport,
main #attempt .elements #group,
main #attempt .elements #leisure,
main #attempt .elements #goingOut {
  padding-top: 10rem;
}

main #attempt .elements #learningSupport .container .titlePart,
main #attempt .elements #individualSupport .container .titlePart,
main #attempt .elements #group .container .titlePart,
main #attempt .elements #leisure .container .titlePart,
main #attempt .elements #goingOut .container .titlePart {
  position: relative;
}

main #attempt .elements #learningSupport .container .titlePart .grayBg,
main #attempt .elements #individualSupport .container .titlePart .grayBg,
main #attempt .elements #group .container .titlePart .grayBg,
main #attempt .elements #leisure .container .titlePart .grayBg,
main #attempt .elements #goingOut .container .titlePart .grayBg {
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  left: 0;
  background-color: #F7F7F7;
  z-index: -2;
}

main #attempt .elements #learningSupport .container .titlePart .wrap,
main #attempt .elements #individualSupport .container .titlePart .wrap,
main #attempt .elements #group .container .titlePart .wrap,
main #attempt .elements #leisure .container .titlePart .wrap,
main #attempt .elements #goingOut .container .titlePart .wrap {
  width: 1000px;
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}

main #attempt .elements #learningSupport .container .titlePart .wrap::before,
main #attempt .elements #individualSupport .container .titlePart .wrap::before,
main #attempt .elements #group .container .titlePart .wrap::before,
main #attempt .elements #leisure .container .titlePart .wrap::before,
main #attempt .elements #goingOut .container .titlePart .wrap::before {
  content: "";
  position: absolute;
  width: 30%;
  height: 50%;
  bottom: 0;
  right: -5%;
  background-color: #D8F5FF;
  z-index: -1;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle,
main #attempt .elements #group .container .titlePart .wrap .innerTitle,
main #attempt .elements #leisure .container .titlePart .wrap .innerTitle,
main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle {
  text-align: left;
  width: 40%;
  height: 100%;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 4rem;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
main #attempt .elements #group .container .titlePart .wrap .innerTitle span.attemptNumber,
main #attempt .elements #leisure .container .titlePart .wrap .innerTitle span.attemptNumber,
main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle span.attemptNumber {
  display: block;
  font-size: 120px;
  font-size: 12rem;
  margin-bottom: 5rem;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #3e3e3e;
}

@media (max-width: 1000px) {
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #group .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #leisure .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle span.attemptNumber {
    margin-bottom: 2rem;
  }
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle h3,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle h3,
main #attempt .elements #group .container .titlePart .wrap .innerTitle h3,
main #attempt .elements #leisure .container .titlePart .wrap .innerTitle h3,
main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle h3 {
  font-size: 60px;
  font-size: 6rem;
  font-weight: 600;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle h3 .strSubSmall,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle h3 .strSubSmall,
main #attempt .elements #group .container .titlePart .wrap .innerTitle h3 .strSubSmall,
main #attempt .elements #leisure .container .titlePart .wrap .innerTitle h3 .strSubSmall,
main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle h3 .strSubSmall {
  font-size: 30px;
  font-size: 3rem;
  display: block;
  margin-top: 1rem;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg,
main #attempt .elements #group .container .titlePart .wrap .innerImg,
main #attempt .elements #leisure .container .titlePart .wrap .innerImg,
main #attempt .elements #goingOut .container .titlePart .wrap .innerImg {
  width: 50%;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg figure,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg figure,
main #attempt .elements #group .container .titlePart .wrap .innerImg figure,
main #attempt .elements #leisure .container .titlePart .wrap .innerImg figure,
main #attempt .elements #goingOut .container .titlePart .wrap .innerImg figure {
  margin-bottom: 8rem;
}

main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg figure img,
main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg figure img,
main #attempt .elements #group .container .titlePart .wrap .innerImg figure img,
main #attempt .elements #leisure .container .titlePart .wrap .innerImg figure img,
main #attempt .elements #goingOut .container .titlePart .wrap .innerImg figure img {
  border-radius: 2rem;
}

main #attempt .elements #learningSupport .container .textPart,
main #attempt .elements #individualSupport .container .textPart,
main #attempt .elements #group .container .textPart,
main #attempt .elements #leisure .container .textPart,
main #attempt .elements #goingOut .container .textPart {
  width: 800px;
  max-width: 90%;
  margin: 5rem auto 10rem auto;
  text-align: left;
}

main #attempt .elements #learningSupport .container .textPart p,
main #attempt .elements #individualSupport .container .textPart p,
main #attempt .elements #group .container .textPart p,
main #attempt .elements #leisure .container .textPart p,
main #attempt .elements #goingOut .container .textPart p {
  line-height: 1.5;
  letter-spacing: 0.1em;
}

main #attempt .elements #individualSupport .container .titlePart .wrap,
main #attempt .elements #leisure .container .titlePart .wrap {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

main #attempt .elements #individualSupport .container .titlePart .wrap::before,
main #attempt .elements #leisure .container .titlePart .wrap::before {
  left: -5%;
}

@media (max-width: 900px) and (max-width: 640px) {
  main #attemptVisual {
    height: 300px;
  }
  main #attemptVisual h1 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media (max-width: 900px) {
  main #attempt .title h2::before {
    left: -4rem;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    height: 100%;
  }
  main #attempt .title h2::after {
    right: -4rem;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    height: 100%;
  }
}

@media (max-width: 900px) and (max-width: 650px) {
  main #attempt .elements #learningSupport,
  main #attempt .elements #individualSupport,
  main #attempt .elements #group,
  main #attempt .elements #leisure,
  main #attempt .elements #goingOut {
    padding-top: 5rem;
  }
}

@media (max-width: 900px) and (max-width: 500px) {
  main #attempt .elements #learningSupport,
  main #attempt .elements #individualSupport,
  main #attempt .elements #group,
  main #attempt .elements #leisure,
  main #attempt .elements #goingOut {
    padding-top: 0rem;
  }
}

@media (max-width: 900px) and (max-width: 400px) {
  main #attempt .elements #learningSupport .container .titlePart .grayBg,
  main #attempt .elements #individualSupport .container .titlePart .grayBg,
  main #attempt .elements #group .container .titlePart .grayBg,
  main #attempt .elements #leisure .container .titlePart .grayBg,
  main #attempt .elements #goingOut .container .titlePart .grayBg {
    height: 62%;
  }
}

@media (max-width: 900px) {
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #group .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #leisure .container .titlePart .wrap .innerTitle span.attemptNumber,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle span.attemptNumber {
    font-size: 80px;
    font-size: 8rem;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle h3,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle h3,
  main #attempt .elements #group .container .titlePart .wrap .innerTitle h3,
  main #attempt .elements #leisure .container .titlePart .wrap .innerTitle h3,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle h3 {
    font-size: 40px;
    font-size: 4rem;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #group .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #leisure .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerImg figure {
    margin-bottom: 3rem;
  }
}

@media (max-width: 900px) and (max-width: 650px) {
  main #attempt .elements #learningSupport .container .titlePart .wrap,
  main #attempt .elements #individualSupport .container .titlePart .wrap,
  main #attempt .elements #group .container .titlePart .wrap,
  main #attempt .elements #leisure .container .titlePart .wrap,
  main #attempt .elements #goingOut .container .titlePart .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap::before,
  main #attempt .elements #individualSupport .container .titlePart .wrap::before,
  main #attempt .elements #group .container .titlePart .wrap::before,
  main #attempt .elements #leisure .container .titlePart .wrap::before,
  main #attempt .elements #goingOut .container .titlePart .wrap::before {
    height: 40%;
    width: 70%;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerTitle,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerTitle,
  main #attempt .elements #group .container .titlePart .wrap .innerTitle,
  main #attempt .elements #leisure .container .titlePart .wrap .innerTitle,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg,
  main #attempt .elements #group .container .titlePart .wrap .innerImg,
  main #attempt .elements #leisure .container .titlePart .wrap .innerImg,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerImg {
    width: 100%;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #group .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #leisure .container .titlePart .wrap .innerImg figure,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerImg figure {
    text-align: center;
  }
  main #attempt .elements #learningSupport .container .titlePart .wrap .innerImg figure img,
  main #attempt .elements #individualSupport .container .titlePart .wrap .innerImg figure img,
  main #attempt .elements #group .container .titlePart .wrap .innerImg figure img,
  main #attempt .elements #leisure .container .titlePart .wrap .innerImg figure img,
  main #attempt .elements #goingOut .container .titlePart .wrap .innerImg figure img {
    width: 500px;
  }
}

#company_visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_company_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#company_visual h1 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

#facility_info {
  background-color: #F3FBFF;
  padding: 2rem 0 10rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#facility_info .container {
  width: 800px;
  max-width: 90%;
  height: auto;
  text-align: center;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #bbb;
  padding: 5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#facility_info .container h2 {
  margin-bottom: 5rem;
  font-size: 30px;
  font-size: 3rem;
  position: relative;
  line-height: 1.6;
}

#facility_info .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#facility_info .container .table {
  width: 100%;
  margin: 0 auto;
}

#facility_info .container .table .t_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  border-bottom: 1px dotted #5e5e5e;
  padding: 2rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#facility_info .container .table .t_row:first-child {
  border-top: 1px dotted #5e5e5e;
}

#facility_info .container .table .t_row .c_head {
  width: 40%;
  font-size: 20px;
  font-size: 2rem;
}

#facility_info .container .table .t_row .c_data {
  width: 60%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
  color: #3e3e3e;
}

#facility_info .container .table .t_row .c_data a {
  color: #3e3e3e;
}

#facility_info .container .table .t_row .c_data p.classroom__label {
  font-weight: 600;
}

#facility_info .container .table .t_row .c_data .classroom {
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  #company_visual {
    min-height: 300px;
  }
  #company_visual h1 {
    font-size: 30px;
    font-size: 3rem;
  }
  #facility_info .container {
    padding: 4rem 2rem;
  }
  #facility_info .container h2 {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media (max-width: 640px) and (max-width: 500px) {
  #facility_info .container .table .t_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 640px) {
  #facility_info .container .table .t_row .c_head {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media (max-width: 640px) and (max-width: 500px) {
  #facility_info .container .table .t_row .c_head {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 640px) {
  #facility_info .container .table .t_row .c_data {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) and (max-width: 500px) {
  #facility_info .container .table .t_row .c_data {
    width: 100%;
  }
}

#contact_visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_contact_visual.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#contact_visual h2 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

#contacts {
  padding: 12rem 0 8rem 0;
}

#contacts .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#contacts .container .tel_mail_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#contacts .container .tel_mail_box .telephone {
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 30px;
  width: 48%;
  height: 30rem;
  position: relative;
}

@media (max-width: 900px) {
  #contacts .container .tel_mail_box .telephone {
    height: 25rem;
  }
}

#contacts .container .tel_mail_box .telephone .wrap {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#contacts .container .tel_mail_box .telephone .wrap .tel-icon {
  position: absolute;
  top: -5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #edf6f6;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 5rem;
}

#contacts .container .tel_mail_box .telephone .wrap .explanation-lbl {
  font-size: 16px;
  font-size: 1.6rem;
}

#contacts .container .tel_mail_box .telephone .wrap .tel-inner {
  margin: 2rem 0 0.5rem 0;
}

#contacts .container .tel_mail_box .telephone .wrap .tel-inner .rep-lbl {
  font-size: 16px;
  font-size: 1.6rem;
}

#contacts .container .tel_mail_box .telephone .wrap .tel-inner a {
  font-size: 30px;
  font-size: 3rem;
  color: #3e3e3e;
  text-decoration: none;
}

#contacts .container .tel_mail_box .telephone .wrap .tel-inner a:hover {
  color: #F9E4A1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#contacts .container .tel_mail_box .telephone .wrap .supplement-txt {
  font-size: 12px;
  font-size: 1.2rem;
}

#contacts .container .tel_mail_box .mail {
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 30px;
  width: 48%;
  height: 30rem;
  position: relative;
}

@media (max-width: 900px) {
  #contacts .container .tel_mail_box .mail {
    height: 25rem;
  }
}

#contacts .container .tel_mail_box .mail .wrap {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#contacts .container .tel_mail_box .mail .wrap .mail-icon {
  position: absolute;
  top: -5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #f8e8eb;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 5rem;
}

#contacts .container .tel_mail_box .mail .wrap .explanation-lbl {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

#contacts .container .tel_mail_box .mail .wrap .mailaddress {
  font-size: 20px;
  font-size: 2rem;
}

#contacts .container .tel_mail_box .mail .wrap .supplement-txt {
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 1rem;
}

#contacts .container .line_girl {
  text-align: right;
  padding-top: 5rem;
}

#contacts .container .line_girl a img {
  width: 70%;
}

#contacts .container .line_girl:hover {
  opacity: 0.5;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

#input_form {
  background-color: #F1FBFF;
  padding-bottom: 5rem;
}

#input_form .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#input_form .container h3 {
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 5rem;
  line-height: 1.6;
  position: relative;
}

#input_form .container h3::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#input_form .container .explanation {
  font-size: 16px;
  font-size: 1.6rem;
  padding-bottom: 5rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

#input_form .container form div.error {
  color: #e70000;
  font-size: 14px;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  font-style: italic;
}

#input_form .container form div.box {
  width: 100%;
  font-size: 20px;
  font-size: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 2.5rem auto 0 auto;
}

#input_form .container form div.box.is-contact {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#input_form .container form div.box .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 45%;
}

#input_form .container form div.box .label p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: left;
}

#input_form .container form div.box .label span.required {
  width: 80px;
  background-color: #00187E;
  padding: 0.8rem 0;
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#input_form .container form div.box .input_text {
  width: 50%;
  text-align: left;
}

#input_form .container form div.box .input_text input,
#input_form .container form div.box .input_text textarea {
  border: 0.5px solid #5e5e5e;
  border-radius: 0.3rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0.2rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#input_form .container form div.box .input_text textarea {
  resize: none;
}

#input_form .container form button {
  background-color: #00187E;
  border: none;
  text-align: center;
  padding: 1rem 5rem;
  font-size: 20px;
  font-size: 2rem;
  border-radius: 3rem;
  margin: 6rem 0 3rem 0;
  cursor: pointer;
  color: #fff;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  font-weight: 600;
}

#input_form .container form button:hover {
  background-color: #0182e6;
}

#confirm_form {
  background-color: #F1FBFF;
  padding-bottom: 5rem;
}

#confirm_form .container {
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#confirm_form .container h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6;
  margin-bottom: 5rem;
  position: relative;
}

#confirm_form .container h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#confirm_form .container .explanation {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

#confirm_form .container .table {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
}

#confirm_form .container .table .t_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  border-bottom: 1px dotted #5e5e5e;
  padding: 2rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#confirm_form .container .table .t_row:first-child {
  border-top: 1px dotted #5e5e5e;
}

#confirm_form .container .table .t_row .c_head {
  width: 40%;
  font-size: 20px;
  font-size: 2rem;
}

#confirm_form .container .table .t_row .c_data {
  width: 60%;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  word-break: break-word;
}

#confirm_form .container .table .t_row .c_data a {
  color: #3e3e3e;
}

#confirm_form .container .table .t_row .c_data a:hover {
  color: #F9E4A1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#confirm_form .container .btn_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-top: 5rem;
}

#confirm_form .container .btn_form button {
  border: none;
  text-align: center;
  padding: 1rem 5rem;
  font-size: 20px;
  font-size: 2rem;
  border-radius: 3rem;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#confirm_form .container .btn_form button.btn_back {
  color: #00187E;
  border: 2px solid #00187E;
  background-color: #fff;
  margin-right: 5rem;
  font-weight: 600;
}

#confirm_form .container .btn_form button.btn_send {
  background-color: #00187E;
  color: #fff;
  font-weight: 600;
}

#confirm_form .container .btn_form button:hover.btn_back {
  opacity: 0.6;
}

#confirm_form .container .btn_form button:hover.btn_send {
  background-color: #0182e6;
}

#thanks {
  padding: 0 0 10rem 0;
}

#thanks .container {
  width: 1200px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#thanks .container p {
  font-size: 30px;
  font-size: 3rem;
  padding: 5rem 0;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

#thanks .container div {
  text-align: center;
  width: 25rem;
  margin: 0 auto;
}

#thanks .container div a {
  color: #fff;
  background-color: #00187E;
  display: block;
  padding: 2rem;
  text-decoration: none;
  font-size: 20px;
  font-size: 2rem;
  border-radius: 3rem;
}

#thanks .container div:hover {
  opacity: 0.6;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 768px) and (max-width: 640px) {
  #contact_visual {
    min-height: 400px;
  }
}

@media (max-width: 768px) and (max-width: 600px) {
  #contact_visual h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  #contacts .container .tel_mail_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #contacts .container .tel_mail_box .telephone {
    width: 500px;
    max-width: 100%;
    margin-bottom: 8rem;
  }
  #contacts .container .tel_mail_box .mail {
    width: 500px;
    max-width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 600px) {
  #contacts .container .tel_mail_box .line_girl a img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #input_form .container h3 {
    font-size: 24px;
    font-size: 2.4rem;
  }
  #input_form .container .explanation {
    font-size: 14px;
    font-size: 1.4rem;
    padding-bottom: 3rem;
  }
  #input_form .container form div.box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  #input_form .container form div.box .label {
    width: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin-bottom: 1rem;
  }
  #input_form .container form div.box .label span.required {
    width: 60px;
    margin-right: 0.5rem;
    font-size: 14px;
    font-size: 1.4rem;
  }
  #input_form .container form div.box .input_text {
    width: 100%;
  }
  #confirm_form .container h3 {
    font-size: 24px;
    font-size: 2.4rem;
  }
  #confirm_form .container .explanation {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) and (max-width: 600px) {
  #confirm_form .container .table .t_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 768px) and (max-width: 600px) {
  #confirm_form .container .table .t_row .c_head {
    margin-bottom: 1rem;
    width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 600px) {
  #confirm_form .container .table .t_row .c_data {
    width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 500px) {
  #confirm_form .container .btn_form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 768px) {
  #confirm_form .container .btn_form button {
    border-radius: 5rem;
  }
  #confirm_form .container .btn_form button.btn_back {
    margin-right: 2rem;
  }
}

@media (max-width: 768px) and (max-width: 500px) {
  #confirm_form .container .btn_form button.btn_back {
    margin-right: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
  #thanks .container p {
    font-size: 20px;
    font-size: 2rem;
  }
  #thanks .container div {
    width: 20rem;
  }
  #thanks .container div a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

#about_visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_about_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#about_visual h1 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
  text-align: center;
  max-width: 90%;
}

#about_support .container {
  width: 1000px;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 8rem;
}

#about_support .container h2 {
  font-size: 25px;
  font-size: 2.5rem;
  padding-top: 3rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

#about_support .container h3 {
  font-size: 20px;
  font-size: 2rem;
  padding-top: 2rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

#about_support .container p {
  padding-top: 1rem;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

#about_support .container .about_support2 h2 {
  padding-top: 5rem;
}

@media (max-width: 768px) {
  #about_visual {
    background-image: url(../../../public/img/img_about_visual_sp.jpg?2022101301);
    min-height: 300px;
  }
  #about_visual h1 {
    font-size: 30px;
    font-size: 3rem;
  }
}

#recruit_visual {
  min-height: 500px;
  background-image: url(../../../public/img/img_recruit_visual.jpg?2022101301);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
}

#recruit_visual h1 {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}

#recruit_info {
  background-color: #F1FBFF;
}

#recruit_info .container {
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  padding: 5rem 0 10rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#recruit_info .container .title {
  text-align: center;
  margin-bottom: 5rem;
}

#recruit_info .container .title h2 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.4;
  position: relative;
  margin-bottom: 3rem;
}

#recruit_info .container .title h2::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url("../../../public/img/img_parts_title_diary_03.png") center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#recruit_info .container .title .explanation {
  line-height: 1.6;
}

#recruit_info .container .contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

#recruit_info .container .contents .recruit {
  width: 48%;
  margin-bottom: 5rem;
  cursor: pointer;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#recruit_info .container .contents .recruit:hover {
  opacity: 0.8;
}

#recruit_info .container .contents .recruit figure {
  position: relative;
}

#recruit_info .container .contents .recruit figure img {
  border: 1px solid #ccc;
  border-radius: 1rem;
}

#recruit_info .container .contents .recruit figure span {
  position: absolute;
  bottom: 0;
  right: -1px;
  display: inline-block;
  background-color: #00187E;
  color: #fff;
  padding: 0.5rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom-right-radius: 1rem;
}

#recruit_info .container .contents .recruit .recruit-name {
  text-align: center;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media (max-width: 600px) {
  #recruit_visual {
    min-height: 300px;
  }
  #recruit_visual h1 {
    font-size: 30px;
    font-size: 3rem;
  }
  #recruit_info .container .title h2 {
    font-size: 26px;
    font-size: 2.6rem;
  }
  #recruit_info .container .title .explanation p {
    font-size: 13px;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) and (max-width: 500px) {
  #recruit_info .container .contents .recruit {
    width: 100%;
  }
}

@media (max-width: 600px) and (max-width: 500px) {
  #recruit_info .container .contents .recruit .recruit-name {
    margin-top: 1rem;
  }
}

#modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
}

#modal.is-active {
  opacity: 1;
  visibility: visible;
}

#modal .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

#modal .wrapper {
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 800px;
  max-width: 90%;
  background-color: #fff;
  padding: 5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#modal .wrapper h2 {
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 5rem;
  position: relative;
  line-height: 1;
}

#modal .wrapper h2::after {
  content: "";
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
  background-size: 100%;
  width: 40px;
  height: 15px;
}

#modal .wrapper .tbl {
  width: 100%;
}

#modal .wrapper .tbl .t-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
  padding: 2rem 0.5rem 2rem 1rem;
  border-bottom: 1px dotted #888;
}

#modal .wrapper .tbl .t-row:first-child {
  border-top: 1px dotted #888;
}

#modal .wrapper .tbl .t-row .t-head {
  width: 30%;
  font-weight: 600;
  font-family: fot-tsukuardgothic-std, sans-serif;
}

#modal .wrapper .tbl .t-row .t-data {
  width: 70%;
  font-family: fot-tsukuardgothic-std, sans-serif;
}

#modal .wrapper .tbl .t-row .t-data p {
  margin-bottom: 1rem;
}

@media (max-width: 450px) {
  #modal .wrapper .tbl .t-row .t-data p {
    margin-bottom: 0.2rem;
  }
}

#modal .wrapper .tbl .t-row .t-data p:last-child {
  margin-bottom: 0;
}

#modal .wrapper .tbl .t-row .t-data.recruitment-type a {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  color: #1852ff;
}

#modal .btn-close {
  position: fixed;
  top: 2%;
  right: 2%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

#modal .btn-close::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 3px;
  background: #fff;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

#modal .btn-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 3px;
  background: #fff;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) and (max-width: 500px) {
  #modal .wrapper {
    padding: 3rem;
  }
}

@media (max-width: 768px) and (max-width: 500px) {
  #modal .wrapper .tbl .t-row {
    padding: 1rem 0.5rem;
  }
}

@media (max-width: 768px) and (max-width: 450px) {
  #modal .wrapper .tbl .t-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 768px) {
  #modal .wrapper .tbl .t-row .t-head {
    width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 450px) {
  #modal .wrapper .tbl .t-row .t-head {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 768px) {
  #modal .wrapper .tbl .t-row .t-data {
    width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 500px) {
  #modal .wrapper .tbl .t-row .t-data {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#scroll-top {
  position: fixed;
  bottom: 3%;
  right: 5%;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #00187E;
  -webkit-box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.3);
          box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 768px) {
  #scroll-top {
    bottom: 1rem;
    right: 1rem;
  }
}

#scroll-top.is-active {
  opacity: 1;
  visibility: visible;
}

#scroll-top:hover {
  background-color: #0182e6;
}

#scroll-top i {
  font-size: 20px;
  font-size: 2rem;
}
