@charset "utf-8";

// ------------------- PC -------------------
// visualPC start
#visual {
    width: 100%;
    height: 100vh;

    .container {
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

#classroom-info {
    background-color: $subColor;
    padding: 100px 0;

    .container {
        width: 900px;
        max-width: 90%;
        margin: 0 auto;

        .title {
            margin-bottom: 80px;

            h2 {
                text-align: center;
                @include fontsize(40);
                position: relative;
                line-height: 1.6;

                &::after {
                    content: "";
                    position: absolute;
                    bottom:-1rem;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: url("../../../public/img/img_parts_title_diary_03.png") center center no-repeat;
                    background-size: 100%;
                    width: 40px;
                    height: 15px;
                }
            }
        }

        .contents {
            display: flex;
            justify-content: space-between;

            .classroom {
                width: 48%;

                a.pic {
                    display: inline-block;
                    position: relative;
                    transition: opacity 0.7s;

                    img {
                        border-radius: 1rem;
                        border: 1px solid #eee;
                    }

                    span {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background:  rgba(0, 24, 126, 0.9);
                        color: #fff;
                        padding: 0.8rem 1rem;
                        border-bottom-right-radius: 1rem;

                        i {
                            margin-left: 0.3rem;
                        }
                    }

                    &:hover {
                        opacity: 0.5;
                    }
                }

                .classroom__info {
                    .sub-title {
                        margin: 1.5rem 0;

                        h3 {
                            @include fontsize(22);
                            font-weight: 700;
                            text-align: center;
                        }
                    }

                    .tbl {
                        .t_row {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 1rem;

                            .t_lbl {
                                border: 1px solid #333;
                                width: 60px;
                                text-align: center;
                                margin-right: 1rem;
                                padding: 0.2rem 0;
                                font-weight: 700;
                                background-color: #fff;
                                @include fontsize(16);
                            }

                            .t_txt {
                                width: calc(100% - 60px);
                                @include fontsize(17);
                                line-height: 1.4;

                                &.telno {
                                    padding: 0.2rem 0;
                                }

                                a.g-map {
                                    color: #00C4FF;
                                    transition: text-decoration 0.5s;
                                    margin-top: 0.5rem;
                                    display: inline-block;
                                    position: relative;

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        bottom: -1px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: 100%;
                                        height: 1.5px;
                                        background: #00C4FF;
                                        opacity: 0;
                                        transition: opacity 0.2s;
                                    }

                                    &:hover {
                                        &::before {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .classroom_sns {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        a.insta-link {
                            margin-top: 0.5rem;
                            padding-right: 2rem;
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 1px ;
                                height: 100%;
                                background: #555;
                                transform: rotate(20deg);
                            }

                            img {
                                width: 28px;
                                transition: opacity 0.5s;
                            }

                            &:hover {
                                img {
                                    opacity: 0.5;
                                }
                            }
                        }

                        a.tiktok-link {
                            margin-top: 0.5rem;
                            padding-left: 2rem;
                            padding-right: 2rem;
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 1px ;
                                height: 100%;
                                background: #555;
                                transform: rotate(20deg);
                            }

                            img {
                                width: 28px;
                                transition: opacity 0.5s;
                            }

                            &:hover {
                                img {
                                    opacity: 0.5;
                                }
                            }
                        }

                        a.litalico-banner {
                            display: block;
                            padding-left: 2rem;

                            img {
                                width: 150px;
                                transition: opacity 0.5s;
                            }
    
                            &:hover {
                                img {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#trouble {
    padding: 10rem 0 15rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        .title {
            @include fontsize(35);
            margin-bottom: 10rem;
            line-height: 1.6;

            .emphasis {
                @include fontsize(50);
                text-decoration: underline;
            }
        }

        picture {
            img {
                width: 1000px;
                max-width: 100%;
                margin: 0 auto;
            }
        }

        .under {
            width: 800px;
            max-width: 90%;
            background-color: #fff;
            @include fontsize(40);
            margin: 10rem auto 0 auto;
            padding: 1.5rem;
            box-sizing: border-box;
            line-height: 1.2;
            background: linear-gradient(120deg, #EA5520, #F49C00, #FFE100, #54B983, #7FCDEC, #8D93C8, #F19EC2);
            position: relative;

            .wrap {
                background: #fff;
                padding: 3rem;
                box-sizing: border-box;

                img {
                    width: 250px;
                    margin-right: 2rem;
                }
            }

            img.staff {
                position: absolute;
                top: 0;
                left: -25rem;
                width: 200px;

                @media (max-width: 1200px) {
                    left: -15rem;
                }

                @media (max-width: 1000px) {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .container {
            img {
                width: 80%;
            }

            p {
                @include fontsize(22);
                margin: 7rem 0;

                .emphasis {
                    @include fontsize(38);
                }
            }
        }
    }
}

#about {
    background-color: $subColor;
    padding: 10rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        margin: 0 auto;
        padding-bottom: 10rem;

        h2 {
            text-align: center;
            @include fontsize(35);
            position: relative;
            margin-bottom: 8rem;
            line-height: 1.6;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;

            .wrap {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                figure {
                    width: 48%;

                    img {
                        border-radius: 1rem;
                        border: 1px solid #ddd;
                    }
                }
    
                .catchphrase {
                    width: 48%;
                    text-align: left;
                    font-weight: bold;

                    p {
                        letter-spacing: 0.3rem;
                        @include fontsize(28);

                        &:first-child {
                            margin-bottom: 2rem;
                        }

                        &:last-child {
                            @include fontsize(32);
                        }

                        img {
                            width: 100px;
                            margin-right: 2rem;
                            vertical-align: bottom;
                        }
                    }
                }
            }

            div.top {
                position: relative;
                text-align: center;
                @include fontsize(16);
                background-color: $baseColor;
                width: 70%;
                margin: -3rem auto 0 auto;
                padding: 6rem 2rem;
                line-height: 1.6;
                letter-spacing: 0.1em;
                box-sizing: border-box;
                box-shadow: 0px 1px 5px #ddd;

                #imgImportant {
                    display: block;
                    width: 500px;
                    max-width: 100%;
                    margin: 3rem auto 0 auto;
                }

                .rainbow {
                    position: absolute;
                    top: -5rem;
                    right: -6rem;
                    width: 150px;
                }

                .about_support {
                    position: absolute;
                    width: 20%;
                    min-width: 120px;
                    bottom: -5rem;
                    left: -8rem;

                    &:hover {
                        opacity: 0.7;
                        transition: 0.8s;
                    }
                }
            }
        }
    }
}

#character {
    padding: 10rem 0 15rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;
        position: relative;

        h2 {
            @include fontsize(35);
            margin-bottom: 10rem;
            position: relative;
            line-height: 1.6;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        figure {
            a {
                display: block;

                img {
                    padding-bottom: 3rem;

                    @media (max-width: 980px) {
                        width: 70%;
                    }
                }
            }
        }

        .elements {
            position: relative;
            margin-top: 3rem;

            .airplane {
                position: absolute;
                top: 0;
                left: 0;

                @media (max-width: 1040px) {
                    width: 15%;
                }
                @media (max-width: 500px) {
                    width: 18%;
                }
            }

            .flower {
                position: absolute;
                bottom: -30rem;
                right: 0;

                @media (max-width: 1040px) {
                    bottom: -25rem;
                    width: 20%;
                }
                @media (max-width: 500px) {
                    bottom: -6rem;
                    width: 30%;
                }
            }

            a {
                transition: all 0.5s;

                &:hover,
                &:active {
                    opacity: 0.8;
                }
            }
        }

        p {
            @include fontsize(20);
            margin: 8rem 0 2rem 0;
            letter-spacing: 0.1em;
            line-height: 1.6;

            @media (max-width: 980px) {
                @include fontsize(15);
            }
        }

        a {
            &.morebtn {
                color: $baseColor;
                @include fontsize(27);
                background-color: #00187E;
                padding: 3rem 0;
                width: 300px;
                max-width: 100%;
                border-radius: 5rem;
                display: block;
                margin: 0 auto;
                transition: background-color 0.5s;

                span {
                    padding-left: 3rem;
                }
    
                &:hover {
                    background-color: #0182e6;
                }
            }
        }
    }
}

#guide {
    background-color: $subColor;
    padding: 10rem 0 15rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h2 {
            @include fontsize(35);
            margin-bottom: 8rem;
            position: relative;
            line-height: 1.6;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .elements {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-wrap: wrap;

            .element {
                width: 30%;
                margin-bottom: 8rem;

                .step {
                    @include fontsize(30);
                    font-weight: 600;
                }

                figure {
                    margin: 1rem 0;
                }

                .stepTitle {
                    @include fontsize(25);
                    font-weight: 600;
                }

                p {
                    @include fontsize(18);
                    margin-top: 3rem;
                    line-height: 1.2;

                    a {
                        color: $fontColor1;
                        text-decoration: underline;
                    }
                }

                &:nth-child(3) {
                    p {
                        margin-top: 3rem;
                    }
                }
            }
        }

        .next_guide {
            p {
                @include fontsize(20);
                margin-bottom: 5rem;
            }

            a {
                color: $baseColor;
                @include fontsize(27);
                background-color: #00187E;
                padding: 2rem 3rem;
                border-radius: 5rem;
                transition: background-color 0.5s;

                span {
                    padding-left: 3rem;
                }

                &:hover {
                    background-color: #0182e6;
                }
            }
        }
    }
}

#contact {
    background-image: url(../../../public/img/img_contact_bg_dot.jpg?2022101301);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10rem 0 15rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h2 {
            @include fontsize(35);
            position: relative;
            line-height: 1.6;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .tel_mail_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12rem;
            line-height: 1;

            .telephone {
                border: 1px solid #000;
                background-color: $baseColor;
                border-radius: 30px;
                width: 48%;
                height: 30rem;
                position: relative;

                div {
                    &.icon_area {
                        position: absolute;
                        top: -5rem;
                        left: 50%;
                        transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        background-color: #edf6f6;
                        padding: 2rem;
                        border: 1px solid #000;
                        border-radius: 50%;
                    }
                }

                .inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .sub-title {
                        @include fontsize(16);
                        margin-bottom: 2rem;
                    }

                    .tel-no {
                        span {
                            display: block;
                            margin-bottom: 0.5rem;
                        }

                        a {
                            @include fontsize(30);
                            text-decoration: none;
                            color: $fontColor1;
                            transition: color 0.5s;
                            display: block;
    
                            &:hover,
                            &:active {
                                color: #0182e6;
                            }
                        }
                    }

                    .supplement {
                        margin-top: 1.5rem;
                        @include fontsize(12);
                    }
                }
            }

            .mail {
                border: 1px solid #000;
                background-color: $baseColor;
                border-radius: 30px;
                width: 48%;
                height: 30rem;
                position: relative;

                div {
                    &.icon_area {
                        position: absolute;
                        top: -5rem;
                        left: 50%;
                        transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        background-color: #f8e8eb;
                        padding: 2rem;
                        border: 1px solid #000;
                        border-radius: 50%;
                    }
                }

                .inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        @include fontsize(16);
                        margin-bottom: 4rem;
                    }

                    a {
                        color: $fontColor1;
                        background-color: $baseColor;
                        border: 1px solid $fontColor1;
                        border-radius: 3rem;
                        padding: 2rem 3rem;
                        @include fontsize(16);
                        transition: background-color 0.5s;

                        &:hover {
                            background-color: #d5edff;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    #visual {
        background-position: 70%;
    }

    #classroom-info {
        @media (max-width: 500px) {
            padding: 70px 0 100px 0;
        }

        .container {
            .title {
                margin-bottom: 50px;

                h2 {
                    @include fontsize(32);
                }
            }

            .contents {
                @media (max-width: 500px) {
                    flex-direction: column;
                }

                .classroom {
                    @media (max-width: 500px) {
                        width: 100%;
                    }

                    &:nth-child(1) {
                        @media (max-width: 500px) {
                            margin-bottom: 5rem;
                        }
                    }

                    a.pic {
                        span {
                            @media (max-width: 500px) {
                                @include fontsize(10);
                            }
                        }
                    }
                }
            }
        }
    }

    #trouble {
        @media (max-width: 500px) {
            padding: 8rem 0;
        }
        
        .container {
            .title {
                @include fontsize(24);
                line-height: 1.5;

                @media (max-width: 500px) {
                    margin-bottom: 5rem;
                }

                .emphasis {
                    @include fontsize(32);
                    border-bottom: none;
                    text-decoration: underline;
                }
            }

            .under {
                @include fontsize(30);
                line-height: 1.5;

                @media (max-width: 600px) {
                    max-width: 100%;
                    margin: 5rem auto 0 auto;
                }

                @media (max-width: 450px) {
                    padding: 0.6rem;
                }

                .wrap {
                    @media (max-width: 450px) {
                        padding: 1rem 0.5rem;
                    }

                    img {
                        @media (max-width: 600px) {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }

    #about {
        .container {
            height: auto;

            h2 {
                @include fontsize(32);
            }

            .inner {
                display: block;

                .wrap {
                    flex-direction: column;

                    figure {
                        width: 100%;

                        img {
                            width: 100%;
                            border-radius: 2rem;
                        }
                    }
    
                    .catchphrase {
                        width: 100%;
                        text-align: center;
                        margin: 3rem 0;

                        p {
                            @media (max-width: 500px) {
                                @include fontsize(22);
                            }
                        }
                    }
                }

                div.top {
                    width: 100%;
                    margin-top: 0;
                    @include fontsize(13);

                    @media (max-width: 550px) {
                        @include fontsize(12);
                    }
                    @media (max-width: 400px) {
                        @include fontsize(11);
                    }

                    .rainbow {
                        top: -3rem;
                        right: 1rem;

                        @media (max-width: 600px) {
                            width: 100px;
                            right: 0;
                        }

                        @media (max-width: 450px) {
                            width: 30%;
                        }
                    }

                    .about_support {
                        bottom: -10rem;
                        left: 0;
                        width: 20%;
                    }
                }
            }
        }
    }

    #character {
        .container {
            h2 {
                @include fontsize(32);
            }

            @media (max-width: 450px) {
                a {
                    &.morebtn {
                        @include fontsize(20);

                        span {
                            img {
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    #guide {
        .container {
            h2 {
                @include fontsize(32);
            }

            .elements {
                flex-wrap: wrap;

                .element {
                    width: 48%;

                    @media (max-width: 500px) {
                        margin-bottom: 5rem;
                    }

                    .step {
                        @include fontsize(25);
                    }

                    .stepTitle {
                        @include fontsize(20);
                    }
                }
            }
        }

        @media (max-width: 450px) {
            .container {
                .elements {
                    .element {
                        p {
                            @include fontsize(14);
                        }
                    }
                }

                .next_guide {
                    p {
                        @include fontsize(16);
                    }

                    a {
                        @include fontsize(20);
                        span {
                            img {
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    #contact {
        background-image: url(../../../public/img/img_contact_bg_dot_sp.jpg?2022101301);
        padding: 10rem 0 0 0;

        .container {
            padding-bottom: 5rem;

            h2 {
                @include fontsize(32);
            }

            .tel_mail_box {
                flex-direction: column;

                .telephone,
                .mail {
                    width: 80%;
                    margin-bottom: 10rem;
                }
            }
        }

        @media (max-width: 550px) {
            .container {
                .tel_mail_box {

                    .telephone,
                    .mail {
                        width: 100%;
                    }
                }
            }
        }
    }
}