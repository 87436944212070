#scroll-top {
    position: fixed;
    bottom: 3%;
    right: 5%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00187E;
    box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
    color: #fff;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    cursor: pointer;
    transition: all 0.5s;

    @media (max-width: 768px) {
        bottom: 1rem;
        right: 1rem;
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &:hover {
        background-color: #0182e6;
    }

    i {
        @include fontsize(20);
    }
}