// pc
#guide-visual {
    min-height: 500px;
    background-image: url(../../../public/img/img_guide_visual.jpg?2022101301);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    h1 {
        @include fontsize(40);
        color: $baseColor;
    }
}

#flow {
    background-color: $subColor;
    padding: 10rem 0;

    @media (max-width: 500px) {
        padding: 5rem 0;
    }

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h2 {
            @include fontsize(40);
            margin-bottom: 5rem;
        }

        .wrap_pc {
            @media (min-width: 700px) {
                display: block;
            }
            @media (max-width: 700px) {
                display: none;
            }

            .step {
                display: flex;
                justify-content: center;
                padding: 5rem 0;
    
                &:nth-child(odd) {
                    flex-direction: row-reverse;
                }
    
                .img_star {
                    width: 53%;

                    img {
                        width: 50%;
                    }
                }
    
                .sentence {
                    width: 47%;
                    background-color: $baseColor;
                    text-align: left;
                    padding: 3rem;
                    border-radius: 1rem;
    
                    h3 {
                        @include fontsize(25);
                        margin-bottom: 2rem;
                        line-height: 1.2;
                        font-weight: 600;
                    }
    
                    p {
                        @include fontsize(15);
                        letter-spacing: 0.1em;
                        line-height: 1.2;
                        color: $fontColor1;
    
                        a {
                            color: $fontColor1;
                        }
                    }
                }
            }
        }

        .wrap_sp {
            @media (min-width: 700px) {
                display: none;
            }
            @media (max-width: 700px) {
                display: block;
            }

            figure {
                margin-bottom: 5rem;
            }

            .stepBody {
                .sentence {
                    width: 100%;
                    background-color: $baseColor;
                    text-align: left;
                    padding: 3rem 1.5rem;
                    box-sizing: border-box;
                    border-radius: 1rem;
                    margin-bottom: 3rem;
                    min-height: 200px;
    
                    h3 {
                        @include fontsize(25);
                        margin-bottom: 2rem;
                        line-height: 1.2;
                        font-weight: 600;
                    }
    
                    p {
                        @include fontsize(16);
                        letter-spacing: 0.1em;
                        line-height: 1.2;
                        color: $fontColor1;
    
                        a {
                            color: $fontColor1;
                        }
                    }
                }
            }
        }
    }
}

#time {
    padding: 10rem 0 20rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;
        padding: 5rem 0;

        h2 {
            @include fontsize(40);
        }

        .time_flex {
            text-align: left;
            margin-top: 15rem;

            .time-sp {
                display: none;
            }
        }
    }
}

#price {
    background-color: $subColor;
    padding: 15rem 0 5rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h2 {
            @include fontsize(40);
        }

        .lead {
            background-color: #C6EFFD;
            position: relative;
            width: 80%;
            margin: 5rem auto;
            padding: 8rem 0;
            border-radius: 3rem;

            p {
                @include fontsize(25);
                letter-spacing: 0.1em;
                line-height: 1.3;

                strong {
                    @include fontsize(50);
                }
            }

            .rainbow {
                position: absolute;
                top: -3rem;
                right: -3rem;
                width: 150px;
            }
        }

        .lead2 {
            margin: 5rem 0 3rem 0;

            p {
                @include fontsize(25);
                letter-spacing: 0.1em;
                line-height: 1.2;

                &:nth-child(2) {
                    @include fontsize(20);
                    color: $fontColor2;
                }

                span {
                    &.max {
                        @include fontsize(40);
                    }
                }
            }
        }

        .table {
            .t_head,
            .t_body {
                display: table;
                width: 100%;

                .c_head {
                    display: table-cell;
                    width: 33.3333%;
                    background-color: $keyColor1;
                    @include fontsize(20);
                    padding: 2rem 3rem;
                    border: 1px solid $fontColor1;
                    letter-spacing: 0.1em;
                    line-height: 1.2;

                    span {
                        @include fontsize(14);
                    }
                }

                .c_data {
                    display: table-cell;
                    border: solid 1px $fontColor1;
                    width: 33.3333%;
                    background-color: #f9f1f3;
                    border: 1px solid $fontColor1;
                    padding: 3rem;
                    @include fontsize(20);
                    letter-spacing: 0.1em;

                    span {
                        @include fontsize(30);
                    }
                }

            }
        }

        .guide_girl {
            text-align: left;
            padding: 3rem 0 8rem 0;

            img {
                width: 50%;
            }
        }
    }

    @media (max-width: 900px) {
        .container {
            .guide_girl {
                img {
                    width: 70%;
                }
            }
        }
    }
}

footer {
    &.guide {
        background-color: $baseColor;
    }
}

// sp start
@media (max-width: 768px) {
    #price {
        .container {
            .lead {

                p {
                    @include fontsize(20);
                }
            }

            .lead2 {
                p {
                    @include fontsize(20);
                }
            }

            .table {
                width: 100%;
                display: flex;

                .t_head,
                .t_body {
                    display: flex;
                    flex-direction: column;

                    .c_head {
                        width: 100%;
                        height: 30%;
                        padding: 2rem 0;
                    }

                    .c_data {
                        width: 100%;
                        height: 30%;
                        padding: 2rem 0;
                    }
                }

            }
        }
    }
}

@media (max-width: 700px) {
    #price {
        .container {
            .lead2 {
                p {
                    &:nth-child(2) {
                        margin-top: 2rem;
                        @include fontsize(16);
                    }
                }
            }
        }
    }

    #flow {
        .container {
            .wrap_pc {
                .step {
                    flex-direction: column;
    
                    &:nth-child(odd) {
                        flex-direction: column;
                    }
    
                    .img_star {
                        width: auto;
                    }
    
                    .sentence {
                        width: 70%;
                        margin: 0 auto;
                        margin-top: 3rem;
                        padding-bottom: 8rem;
    
                        h3 {
                            text-align: center;
                        }
    
                        p {
                            padding: 0 3rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    #guide-visual {
        min-height: 300px;

        h1 {
            @include fontsize(30);
        }
    }

    #flow {
        .container {
            h2 {
                @include fontsize(30);
            }

            .wrap_pc {
                .step {
                    .sentence {
                        width: auto;
                        margin: 3rem 0 0 0;
    
                        p {
                            padding: 0;
                        }
    
                    }
                }
            }
        }
    }

    #time {
        padding: 6rem 0;

        .container {
            h2 {
                @include fontsize(30);
            }

            .time_flex {
                margin-top: 5rem;

                .time-sp {
                    @media (max-width: 500px) {
                        display: block;
                        margin-top: 3rem;
                    }
                }
            }
        }
    }

    #price {
        .container {
            h2 {
                padding-top: 2rem;
                @include fontsize(30);
            }

            .lead {
                width: auto;
                padding: 5rem 0;
                border-radius: 2rem;

                .rainbow {
                    width: 100px;
                    right: -1rem;
                    top: -1rem;

                    @media (max-width: 450px) {
                        width: 80px;
                    }
                }
            }

            .lead2 {
                p {
                    &:nth-child(2) {
                        margin-top: 2rem;
                        @include fontsize(16);
                    }
                }
            }

            .table {
                .t_head {
                    .c_head {
                        @include fontsize(16);

                        span {
                            @include fontsize(11);
                        }
                    }
                }
            }

            .guide_girl {
                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    #price {
        .container {
            .guide_woman {
                width: 40%;
                margin: 0 auto;
            }
        }
    }
}