@charset "utf-8";

// headerPC start
header {
    position: fixed;
    background-color: rgba(255,255,255,0.95);
    width: 100%;
    min-width: 320px;
    max-width: 2500px;
    margin: 0 auto;
    height: auto;
    top: 0;
    z-index: 99;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.3);

    .container {
        width: 90%;
        max-width: 1500px;
        margin: 1.5rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;  

        .headA {
            h1,
            .head-logo {
                box-sizing: border-box;

                a {
                    img {
                        width: 180px;
                    }
                }
            }
        }

        .headB {
            .nav_pc {
                display: block;

                ul.nav-lists {
                    display: flex;
                    justify-content: space-between;

                    li {
                        position: relative;
                        display: inline-block;

                        &:not(:first-child) {
                            margin-left: 2rem;
                        }

                        a {
                            text-align: center;
                            display: block;
                            
                            img {
                                height: 45px;
                                transition: opacity 0.5s;
                            }

                            div {
                                margin-top: 0.5rem;
                                color: #3e3e3e;
                                @include fontsize(16);
                                font-weight: 600;
                                line-height: 1.2;
                                transition: opacity 0.5s;

                                @media (max-width: 1100px) {
                                    @include fontsize(12);
                                }

                                i {
                                    margin-left: 0.3rem;
                                }
                            }
                        }

                        .child-menu {
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            bottom: -9rem;
                            left: 0;
                            width: 150px;
                            background-color: #fff;
                            box-shadow: 0 0 2px rgba(0,0,0,0.4);
                            transition: opacity 0.5s, visibility 0.5s;

                            li {
                                margin-left: 0;
                                height: 4rem;
                                line-height: 4rem;
                                @include fontsize(16);
                                display: block;

                                &:not(:last-child) {
                                    border-bottom: 1px dotted #333;
                                }

                                a {
                                    transition: color 0.5s;
                                }

                                &:hover,
                                &:active {
                                    a {
                                        color:#0182e6;
                                    }
                                }
                            }
                        }

                        &:hover,
                        &:active {
                            a {
                                img {
                                    opacity: 0.6;
                                }

                                div {
                                    opacity: 0.6;
                                }
                            }

                            .child-menu {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            .nav_sp {
                display: none;
            }
        }

        .headC {
            display: none;
        }
    }
}

// headerPC end

@media (max-width: 900px) {

    // header SP start
    header {
        position: fixed;

        .container {
            position: relative;
            margin: 0.5rem auto;

            .headA {
                width: 100%;
                
                h1,
                .head-logo {
                    padding: 0;
                    
                    a {
                        img {
                            width: 150px;
                            margin: 1rem 0;
                        }
                    }
                }
            }

            .headB {
                .nav_pc {
                    display: none;
                }

                .nav_sp {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background-color: rgba(255,255,255,0.95);
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.5s, visibility 0.5s;

                    &.is-active {
                        opacity: 1;
                        visibility: visible;
                    }

                    ul.nav-lists {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        margin-top: 10rem;

                        li {
                            text-align: center;
                            margin-bottom: 3rem;

                            a {
                                display: block;
                                color: $fontColor1;
                                @include fontsize(24);
                                cursor: pointer;
                                transition: 0.4s;

                                i {
                                    margin-left: 0.5rem;
                                }
                                
                                &:hover {
                                    color: #0182e6;
                                }
                            }

                            ul.child-menu {
                                display: block;
                                visibility: hidden;
                                opacity: 0;
                                margin-top: 0;
                                height: 0;
                                transition: all 0.5s;
                                background-color:#00187E;

                                &.is-active {
                                    visibility: visible;
                                    opacity: 1;
                                    margin-top: 2rem;
                                    height: auto;
                                }

                                li {
                                    margin-bottom: 1rem;
                                    padding: 1rem 0;
                                    
                                    &:not(:last-child) {
                                        border-bottom: 1px dotted #fff;
                                        margin-bottom: 0;
                                    }

                                    a {
                                        color: #fff;
                                        font-weight: 400;
                                        @include fontsize(18);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .headC {
                display: block;
                position: absolute;
                top: 20%;
                right: 0;

                .menu {
                    width: 50px;
                    height: 50px;
                    position: relative;

                    &:before,
                    &:after,
                    span {
                        content: "";
                        border-bottom: 2px solid $fontColor1;
                        position: absolute;
                        top: 50%;
                        left: 5px;
                        right: 5px;
                        transition: 0.3s;
                        z-index: 11;
                    }

                    &:before {
                        transform: translateY(-13px);
                    }

                    &:after {
                        transform: translateY(13px);
                    }

                    &.is-active {
                        span {
                            opacity: 0;
                        }

                        &::before {
                            transform: rotate(-45deg);
                        }

                        &::after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }

    // header SP end
}


header {
    &#early-rehabilitation {
        padding: 1rem 0;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.2);

        .container {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .headA {
                .head-logo {
                    img {
                        width: 180px;
                    }
                }
            }

            .headB {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @include fontsize(16);

                .tel {
                    display: flex;
                    align-items: center;

                    i {
                        @include fontsize(30);
                        margin-right: 1rem;
                        color: #333;
                    }

                    .inner {
                        .number {
                            display: block;
                            @include fontsize(28);
                            color: #333;
                        }
                        span {
                            display: block;
                            @include fontsize(14);
                            color: #888;
                            margin-top: 0.5rem;
                            font-family: fot-tsukuardgothic-std, sans-serif;
                        }
                    }
                }

                .line {
                    @include fontsize(40);
                    margin-left: 4rem;
                    color: #333;
                }

                .mail {
                    @include fontsize(40);
                    margin-left: 2rem;
                    color: #333;
                }
            }
        }

        @media (max-width: 900px) {
            .container {
                .headA {
                    width: auto;

                    .head-logo {
                        margin-right: 2rem;
                    }
                }
                .headB {
                    width: auto;

                    .tel {
                        i {
                            margin-right: 0;
                        }

                        .inner {
                            display: none;
                        }
                    }

                    .line {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
}

header {
    &#recruit2 {
        .container-r2 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .headA {
                padding-left: 5%;

                h1,
                .head-logo {    
                    a {
                        img {
                            width: 180px;

                            @media (max-width: 420px) {
                                width: 150px;
                            }
                        }
                    }
                }
            }

            .headB {
                display: flex;

                .contact-link {
                    display: inline-block;
                    color: #00B939;
                    text-align: center;
                    padding: 1rem 1rem 1rem 0;
                    box-sizing: border-box;
                    transition: opacity 0.5s;

                    &:hover,
                    &:active {
                        opacity: 0.6;
                    }

                    @media (max-width: 768px) {
                        padding: 2rem 1rem 2rem 0;
                    }
                    @media (max-width: 500px) {
                        padding: 1rem 1rem 1rem 0;
                    }

                    span {
                        background-color: #19B939;
                        color: #fff;
                        display: inline-block;
                        padding: 0.2rem 0;
                        width: 90%;
                        border-radius: 3rem;
                        box-sizing: border-box;
                        font-family: fot-tsukuardgothic-std, sans-serif;
                        font-weight: 400;
                        @include fontsize(14);

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }

                    .wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 0.5rem;

                        @media (max-width: 768px) {
                            flex-direction: column;
                        }

                        img {
                            width: 30px;
                            margin-right: 0.5rem;

                            @media (max-width: 768px) {
                                width: 40px;
                                margin-right: 0;
                                margin-bottom: 0.5rem;
                            }
                        }

                        .inner {
                            .tel-no {
                                @include fontsize(30);
                                line-height: 1;

                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }

                            .supplement {
                                @include fontsize(12);

                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }

                            .sp-label {
                                display: none;

                                @media (max-width: 768px) {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .mail-link {
                    background-color:#00B939;
                    color: #fff;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 0 1rem;
                    box-sizing: border-box;
                    transition: opacity 0.5s;

                    &:hover,
                    &:active {
                        opacity: 0.6;
                    }

                    @media (max-width: 768px) {
                        padding: 0 1.5rem;
                    }

                    img {
                        width: 30px;
                        margin-bottom: 0.5rem;

                        @media (max-width: 768px) {
                            width: 40px;
                        }
                    }

                    p {
                        &.pc-label {
                            display: block;
                            
                            @media (max-width: 768px) {
                                display: none;
                            }
                        }

                        &.sp-label {
                            display: none;

                            @media (max-width: 768px) {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-contents-menu {
    position: fixed;
    bottom: 5%;
    right: 5%;
    text-align: center;
    width: 100px;
    height: 100px;
    line-height: 100px;
    background-color: #FF9300;
    color: #fff;
    @include fontsize(20);
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
    z-index: 5;
    cursor: pointer;
}

.table-contents-menu-body {
    display: none;
    position: fixed;
    bottom: 3%;
    right: 3%;
    width: 300px;
    height: 500px;
    background-color: rgba(255,255,255,0.95);
    z-index: 4;
    padding: 3rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3);

    h3 {
        @include fontsize(24);
        font-weight: 600;
        margin-bottom: 3rem;
    }

    ul {
        li {
            margin-bottom: 3rem;
            
            a {
                @include fontsize(14);
                display: block;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 1px;
                    border-bottom: 3px dotted #FF9300;
                    bottom: -0.5rem;
                    left: 0;
                }
            }
        }
    }
}