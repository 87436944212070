@charset "utf-8";

// pc start
// contact_visual pc start
#contact_visual {
    min-height: 500px;
    background-image: url(../../../public/img/img_contact_visual.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    h2 {
        @include fontsize(40);
        color: $baseColor;
    }
}

// contact_visual pc end
#contacts {
    padding: 12rem 0 8rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        .tel_mail_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .telephone {
                border: 1px solid #000;
                background-color: $baseColor;
                border-radius: 30px;
                width: 48%;
                height: 30rem;
                position: relative;

                @media (max-width: 900px) {
                    height: 25rem;
                }

                .wrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .tel-icon {
                        position: absolute;
                        top: -5rem;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #edf6f6;
                        padding: 2rem;
                        border: 1px solid #000;
                        border-radius: 5rem;
                    }
    
                    .explanation-lbl {
                        @include fontsize(16);
                    }
    
                    .tel-inner {
                        margin: 2rem 0 0.5rem 0;
    
                        .rep-lbl {
                            @include fontsize(16);
                        }
    
                        a {
                            @include fontsize(30);
                            color: $fontColor1;
                            text-decoration: none;
    
                            &:hover {
                                color: $keyColor3;
                                transition: 0.5s;
                            }
                        }
                    }
    
                    .supplement-txt {
                        @include fontsize(12);
                    }
                }
            }

            .mail {
                border: 1px solid #000;
                background-color: $baseColor;
                border-radius: 30px;
                width: 48%;
                height: 30rem;
                position: relative;

                @media (max-width: 900px) {
                    height: 25rem;
                }

                .wrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                
                    .mail-icon {
                        position: absolute;
                        top: -5rem;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #f8e8eb;
                        padding: 2rem;
                        border: 1px solid #000;
                        border-radius: 5rem;
                    }
    
                    .explanation-lbl {
                        @include fontsize(16);
                        margin-bottom: 2rem;
                    }
    
                    .mailaddress {
                        @include fontsize(20);
                    }

                    .supplement-txt {
                        @include fontsize(12);
                        margin-top: 1rem;
                    }
                }
            }
        }

        .line_girl {
            text-align: right;
            padding-top: 5rem;

            a {
                img {
                    width: 70%;
                }
            }

            &:hover {
                opacity: 0.5;
                transition: 0.8s;
            }
        }
    }
}

// contactPC end

// inputform pc start
#input_form {
    background-color: $subColor;
    padding-bottom: 5rem;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h3 {
            @include fontsize(30);
            margin-bottom: 5rem; 
            line-height: 1.6;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .explanation {
            @include fontsize(16);
            padding-bottom: 5rem;
            line-height: 1.2;
            letter-spacing: 0.1em;
        }

        form {
            div {
                &.error {
                    color: rgb(231, 0, 0);
                    @include fontsize(14);
                    margin-top: 0.5rem;
                    font-style: italic;
                }

                &.box {
                    width: 100%;
                    @include fontsize(20);
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin: 2.5rem auto 0 auto;

                    &.is-contact {
                        align-items: flex-start;
                    }

                    .label {
                        display: flex;
                        align-items: center;
                        width: 45%;

                        p {
                            flex: 1;
                            text-align: left;
                        }

                        span {
                            &.required {
                                width: 80px;
                                background-color: #00187E;
                                padding: 0.8rem 0;
                                @include fontsize(16);
                                color: #fff;
                                box-sizing: border-box;
                            }
                        }
                    }

                    .input_text {
                        width: 50%;
                        text-align: left;

                        input,
                        textarea {
                            border: 0.5px solid #5e5e5e;
                            border-radius: 0.3rem;
                            line-height: 1.6;
                            letter-spacing: 0.1em;
                            width: 100%;
                            @include fontsize(16);
                            padding: 0.2rem 1rem;
                            box-sizing: border-box;
                        }

                        textarea {
                            resize: none;
                        }
                    }
                }
            }

            button {
                background-color: #00187E;
                border: none;
                text-align: center;
                padding: 1rem 5rem;
                @include fontsize(20);
                border-radius: 3rem;
                margin: 6rem 0 3rem 0;
                cursor: pointer;
                color: #fff;
                transition: background-color 0.5s;
                font-weight: 600;

                &:hover {
                    background-color: #0182e6;
                }
            }
        }
    }
}

// inputform pc end

// confirm_form pc start
#confirm_form {
    background-color: $subColor;
    padding-bottom: 5rem;

    .container {
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h2 {
            @include fontsize(30);
            line-height: 1.6;
            margin-bottom: 5rem;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom:-1rem;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(../../../public/img/img_parts_title_diary_03.png?2022080301) center center no-repeat;
                background-size: 100%;
                width: 40px;
                height: 15px;
            }
        }

        .explanation {
            @include fontsize(16);
            margin-bottom: 5rem;
            line-height: 1.2;
            letter-spacing: 0.1em;
        }

        .table {
            width: 800px;
            max-width: 100%;
            margin: 0 auto;

            .t_row {
                display: flex;
                text-align: left;
                border-bottom: 1px dotted $fontColor2;
                padding: 2rem 1rem;
                box-sizing: border-box;

                &:first-child {
                    border-top: 1px dotted $fontColor2;
                }

                .c_head {
                    width: 40%;
                    @include fontsize(20);
                }

                .c_data {
                    width: 60%;
                    @include fontsize(20);
                    line-height: 1.2;
                    letter-spacing: 0.1em;
                    word-break: break-word;

                    a {
                        color: $fontColor1;

                        &:hover {
                            color: $keyColor3;
                            transition: 0.5s;
                        }
                    }
                }
            }
        }

        .btn_form {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            margin-top: 5rem;

            button {
                border: none;
                text-align: center;
                padding: 1rem 5rem;
                @include fontsize(20);
                border-radius: 3rem;
                cursor: pointer;
                transition: all 0.5s;

                &.btn_back {
                    color: #00187E;
                    border: 2px solid #00187E;
                    background-color: #fff;
                    margin-right: 5rem;
                    font-weight: 600;
                }

                &.btn_send {
                    background-color: #00187E;
                    color: #fff;
                    font-weight: 600;
                }

                &:hover {
                    &.btn_back {
                        opacity: 0.6;
                    }
                    &.btn_send {
                        background-color: #0182e6;
                    }
                }
            }
        }
    }
}

// confirm_form pc end

#thanks {
    padding: 0 0 10rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        p {
            @include fontsize(30);
            padding: 5rem 0;
            line-height: 1.2;
            letter-spacing: 0.1em;
        }

        div {
            text-align: center;
            width: 25rem;
            margin: 0 auto;

            a {
                color: #fff;
                background-color: #00187E;
                display: block;
                padding: 2rem;
                text-decoration: none;
                @include fontsize(20);
                border-radius: 3rem;
            }

            &:hover {
                opacity: 0.6;
                transition: 0.5s;
            }
        }
    }
}

// pc end

// sp start
@media (max-width: 768px) {
    #contact_visual {
        @media (max-width: 640px) {
            min-height: 400px;
        }

        h2 {
            @media (max-width: 600px) {
                @include fontsize(30);
            }
        }
    }

    #contacts {
        .container {
            .tel_mail_box {
                flex-direction: column;

                .telephone {
                    width: 500px;
                    max-width: 100%;
                    margin-bottom: 8rem;
                }

                .mail {
                    width: 500px;
                    max-width: 100%;
                }

                .line_girl {
                    a {
                        img {
                            @media (max-width: 600px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    #input_form {
        .container {
            h3 {
                @include fontsize(24);
            }

            .explanation {
                @include fontsize(14);
                padding-bottom: 3rem;
            }

            form {
                div {
                    &.box {
                        flex-direction: column;
                        align-items: flex-start;

                        .label {
                            width: auto;
                            flex-direction: row-reverse;
                            margin-bottom: 1rem;

                            span {
                                &.required {
                                    width: 60px;
                                    margin-right: 0.5rem;
                                    @include fontsize(14);
                                }
                            }
                        }

                        .input_text {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    #confirm_form {
        .container {
            h3 {
                @include fontsize(24);
            }

            .explanation {
                @include fontsize(14);
            }

            .table {
                .t_row {
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }

                    .c_head {
                        @media (max-width: 600px) {
                            margin-bottom: 1rem;
                            width: 100%;
                        }
                    }

                    .c_data {
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
            }

            .btn_form {
                @media (max-width: 500px) {
                    flex-direction: column;
                }

                button {
                    border-radius: 5rem;

                    &.btn_back {
                        margin-right: 2rem;

                        @media (max-width: 500px) {
                            margin-right: 0;
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    #thanks {
        .container {
            p {
                @include fontsize(20);
            }

            div {
                width: 20rem;

                a {
                    @include fontsize(16);
                }
            }
        }
    }
}

// sp end