#classroom-visual {
    min-height: 500px;
    background-image: url(../../../public/img/img_classroom_visual.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    h2 {
        @include fontsize(40);
        color: $baseColor;
    }
}

#classroom-info-dtl {
    padding: 5rem 0 10rem 0;

    .container {
        width: 1200px;
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .l-area {
            width: 49%;
        }
        .r-area {
            width: 49%;

            .classroom__info {
                margin-top: 2rem;

                h3 {
                    @include fontsize(30);
                    margin-bottom: 2rem;
                }

                .tbl {
                    .t_row {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 1rem;

                        .t_lbl {
                            width: 50px;
                            margin-right: 1rem;
                            padding: 0.2rem 0;
                            border: 1px solid #333;
                            display: inline;
                            text-align: center;
                            @include fontsize(16);
                        }

                        .t_txt {
                            width: calc(100% - 50px);
                            @include fontsize(17);
                            line-height: 1.4;

                            &.telno {
                                padding: 0.2rem 0;
                            }
                        }
                    }
                }

                .classroom_sns {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;
                    
                    a.insta-link {
                        margin-top: 0.5rem;
                        margin-right: 2rem;

                        img {
                            width: 28px;
                            transition: opacity 0.5s;
                        }

                        &:hover {
                            img {
                                opacity: 0.5;
                            }
                        }
                    }

                    a.tiktok-link {
                        margin-top: 0.5rem;
                        margin-right: 2rem;

                        img {
                            width: 28px;
                            transition: opacity 0.5s;
                        }

                        &:hover {
                            img {
                                opacity: 0.5;
                            }
                        }
                    }

                    a.litalico-banner {
                        display: block;

                        img {
                            width: 200px;
                            transition: opacity 0.5s;
                        }

                        &:hover {
                            img {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .classroom-map {
                width: 100%;
                height: 200px;
                overflow: hidden;
            
                iframe {
                    width: 100%;
                    height: 560px;
                    margin-top: -170px;
                }
            }
        }
    }
}

#classroom-gallery {
    padding: 3rem 0 10rem 0;

    .container {
        width: 1500px;
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        figure {
            width: 32%;
            margin-bottom: 2.5rem;
        }
    }
}

#classroom-assessment-sheet {
    padding: 10rem 0 20rem 0;

    .container {
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;

        .title {
            text-align: center;
            margin-bottom: 8rem;

            h3 {
                @include fontsize(40);
                position: relative;
                line-height: 1.6;

                &::after {
                    content: "";
                    position: absolute;
                    bottom:-1rem;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: url(../../../public/img/img_parts_title_diary_03.png?2022101301) center center no-repeat;
                    background-size: 100%;
                    width: 40px;
                    height: 15px;
                }
            }
        }


        .downloadContents {
            .card {
                display: flex;
                align-items: center;
                border-bottom: 1px dashed #888;
                padding: 2rem;

                &:first-child {
                    border-top: 1px dashed #888;
                }

                figure {
                    width: 70px;
                    margin-right: 1rem;
                }

                .inner {
                    width: 90%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .fileName {
                        @include fontsize(20);
                        margin-bottom: 1rem;
                        font-weight: 600;
                        letter-spacing: 0.1em;
                        line-height: 1.2;
                    }

                    a {
                        display: block;
                        width: 200px;
                        max-width: 100%;
                        text-align: center;
                        height: 40px;
                        line-height: 40px;
                        color: #fff;
                        background-color: rgb(0, 226, 151);
                        border: 2px solid rgb(0, 226, 151);
                        @include fontsize(16);
                        border-radius: 2rem;
                        transition: all 0.5s;

                        &:hover,
                        &:active {
                            background-color: #fff;
                            color: rgb(0, 226, 151);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    #classroom-visual {
        @media (max-width: 600px) {
            min-height: 400px;
        }

        h2 {
            @media (max-width: 600px) {
                @include fontsize(30);
            }
        }
    }

    #classroom-info-dtl {
        padding: 3rem 0 8rem 0;

        .container {
            flex-direction: column;

            .l-area {
                width: 100%;
                text-align: center;

                img {
                    width: 500px;
                }
            }

            .r-area {
                width: 100%;

                .classroom__info {
                    h3 {
                        @include fontsize(22);
                        text-align: center;
                        margin: 1rem 0;
                    }

                    .tbl {
                        width: 60%;
                        margin: 0 auto;

                        @media (max-width: 600px) {
                            width: 80%;
                        }
                        @media (max-width: 450px) {
                            width: 100%;
                        }

                        .t_row {
                            justify-content: center;
                        }
                    }

                    .classroom_sns {
                        justify-content: center;
                    }
                }
            }
        }
    }

    #classroom-gallery {
        padding-bottom: 6rem;

        .container {
            figure {
                @media (max-width: 500px) {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media (max-width: 768px) {
        #classroom-assessment-sheet {
            .container {
                .title {
                    h3 {
                        @media (max-width: 640px) {
                            @include fontsize(30);
                        }
                    }
                }
    
                .downloadContents {
                    .card {
                        padding: 2rem 1rem;
    
                        @media (max-width: 380px) {
                            padding: 2rem 0;
                        }
    
                        .inner {
                            flex-direction: column;
                            align-items: flex-start;
    
                            .fileName {
                                @media (max-width: 500px) {
                                    @include fontsize(18);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}