@charset "utf-8";

// colors
$baseColor: #ffffff;
$subColor: #F1FBFF;
$keyColor1: #F6D7DD;
$keyColor2: #CEE8E7;
$keyColor3: #F9E4A1;
$fontColor1: #3e3e3e;
$fontColor2: #5e5e5e;

/* base
------------------------------------------------------------*/

html {
    font-size: 62.5%;

    body {
        font-family: 'GenJyuuGothic-Medium';
        color: $fontColor1;
        min-width: 320px;
        max-width: 2500px;
        margin: 0 auto;
        @include fontsize(15);

        @media (max-width: 768px) {
            @include fontsize(14);
        }
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

// 大文字に変換
.uppercase {
    text-transform: uppercase;
}

// 改行
span {
    &.text {
        display: inline-block;
    }
}

a {
    color: #333;
}

.card-hide {
    display: none;
}

// panlist start
#panlist {
    padding: 3rem 0;
    background-color: $subColor;

    &.bg-white {
        background-color: $baseColor;
    }

    .container {
        width: 1200px;
        max-width: 90%;
        height: auto;
        margin: 0 auto;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                @include fontsize(16);
                line-height: 1.6;

                @media (max-width: 640px) {
                    @include fontsize(14);
                }

                &.home {
                    a {
                        text-decoration: underline;
                        color: $fontColor1;
                    }
                }

                a {
                    transition: color 0.5s;
                    
                    &:hover,
                    &:active {
                        color: #0182e6;
                    }
                }
            }
        }
    }
}

// panlist end

/* パソコンで見たときは"pc"のclassがついた画像が表示される */

.pc {
    display: inline !important;
}

.sp {
    display: none !important;
}

/* スマートフォンで見たときは"sp"のclassがついた画像が表示される */

@media only screen and (max-width: 768px) {

    .pc {
        display: none !important;
    }

    .sp {
        display: inline !important;
    }
}

// .titleTrigger {
//     // overflow: hidden;
//     span {
//         display: inline-block;
//     }
// }