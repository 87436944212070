main {
    #attemptVisual {
        height: 500px;
        background-image: url(../../../public/img/img_attempt_visual.jpg?2022101301);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;

        h1 {
            @include fontsize(40);
            color: $baseColor;
        }
    }

    #panlist {
        &.p_attempt {
            background-color: #fff;
        }
    }

    #attempt {
        @include fontsize(16);
        padding-bottom: 10rem;

        .title {
            text-align: center;
            margin-top: 5rem;

            h2 {
                @include fontsize(35);
                display: inline-block;
                margin: 0 auto;
                position: relative;
                letter-spacing: 0.1em;

                span {
                    &.number {
                        color: #F500FF;
                        @include fontsize(60);
                    }

                    &.strSmall {
                        @include fontsize(28);
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: -8rem;
                    width: 2px;
                    height: 120%;
                    background-color: $fontColor1;
                    transform: rotate(-35deg);
                }

                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    right: -8rem;
                    width: 2px;
                    height: 120%;
                    background-color: $fontColor1;
                    transform: rotate(35deg);
                }
            }

            p {
                max-width: 90%;
                margin: 0 auto;
                text-align: center;
                padding: 5rem 0;
                letter-spacing: 0.1em;
                line-height: 1.4;
            }
        }

        .elements {

            #learningSupport,
            #individualSupport,
            #group,
            #leisure,
            #goingOut {
                padding-top: 10rem;

                .container {
                    .titlePart {
                        position: relative;

                        .grayBg {
                            position: absolute;
                            width: 100%;
                            height: 70%;
                            bottom: 0;
                            left: 0;
                            background-color: #F7F7F7;
                            z-index: -2;
                        }

                        .wrap {
                            width: 1000px;
                            max-width: 90%;
                            margin: 0 auto;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 30%;
                                height: 50%;
                                bottom: 0;
                                right: -5%;
                                background-color: #D8F5FF;
                                z-index: -1;
                            }

                            .innerTitle {
                                text-align: left;
                                width: 40%;
                                height: 100%;
                                position: relative;
                                box-sizing: border-box;
                                padding-top: 4rem;

                                span {
                                    &.attemptNumber {
                                        display: block;
                                        @include fontsize(120);
                                        margin-bottom: 5rem;
                                        color: rgba(0, 0, 0, .0);
                                        -webkit-text-stroke: 1px $fontColor1;

                                        @media (max-width:1000px) {
                                            margin-bottom: 2rem;
                                        }
                                    }
                                }

                                h3 {
                                    @include fontsize(60);
                                    font-weight: 600;

                                    .strSubSmall {
                                        @include fontsize(30);
                                        display: block;
                                        margin-top: 1rem;
                                    }
                                }
                            }

                            .innerImg {
                                width: 50%;

                                figure {
                                    margin-bottom: 8rem;

                                    img {
                                        border-radius: 2rem;
                                    }
                                }
                            }
                        }
                    }

                    .textPart {
                        width: 800px;
                        max-width: 90%;
                        margin: 5rem auto 10rem auto;
                        text-align: left;

                        p {
                            line-height: 1.5;
                            letter-spacing: 0.1em;
                        }
                    }
                }
            }

            #individualSupport,
            #leisure {
                .container {
                    .titlePart {
                        .wrap {
                            flex-direction: row-reverse;

                            &::before {
                                left: -5%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:900px) {
        #attemptVisual {
            @media (max-width: 640px) {
                height: 300px;

                h1 {
                    @include fontsize(30);
                }
            }
        }

        #attempt {
            .title {
                h2 {
                    &::before {
                        left: -4rem;
                        transform: rotate(-15deg);
                        height: 100%;
                    }

                    &::after {
                        right: -4rem;
                        transform: rotate(15deg);
                        height: 100%;
                    }
                }
            }

            .elements {
                #learningSupport,
                #individualSupport,
                #group,
                #leisure,
                #goingOut {
                    @media (max-width: 650px) {
                        padding-top: 5rem;
                    }
                    @media (max-width: 500px) {
                        padding-top: 0rem;
                    }

                    .container {
                        .titlePart {
                            .grayBg {
                                @media (max-width: 400px) {
                                    height: 62%;
                                }
                            }

                            .wrap {
                                .innerTitle {
                                    span {
                                        &.attemptNumber {
                                            @include fontsize(80);
                                        }
                                    }

                                    h3 {
                                        @include fontsize(40);
                                    }
                                }

                                .innerImg {
                                    figure {
                                        margin-bottom: 3rem;
                                    }
                                }

                                @media (max-width: 650px) {
                                    flex-direction: column;

                                    &::before {
                                        height: 40%;
                                        width: 70%;
                                    }

                                    .innerTitle {
                                        width: 100%;
                                        text-align: center;
                                        margin-bottom: 5rem;
                                    }
                                    .innerImg {
                                        width: 100%;

                                        figure {
                                            text-align: center;

                                            img {
                                                width: 500px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}