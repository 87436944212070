/* fontsize
------------------------------------------------------------*/

@mixin fontsize($size: 24) {
  font-size: $size + px;
  font-size: ($size / 10) * 1rem;
}

/* background
------------------------------------------------------------*/
$PATH_IMG: "../img/";

@mixin background(
    $image-name,
    $repeat: no-repeat,
    $position: center,
    $size: cover,
) {
    background:  {
        image: url($PATH_IMG + $image-name);
        repeat: $repeat;
        position: $position;
        size: $size;
    }
}